import { isMobile } from 'react-device-detect'

const getQuickUserMenuItems = () => {
  return [
    {
      slug: 'my-account-settings',
      title: 'Minha Conta',
      href: '/minha-conta/configuracoes'
    },
    {
      slug: 'my-connections',
      title: 'Minhas Conexões',
      href: '/minha-conta/conexoes'
    },
    {
      slug: 'my-certificates',
      title: 'Meus Certificados',
      href: '/minha-conta/certificados'
    },
    {
      slug: 'change-password',
      title: 'Alterar Senha',
      href: '/minha-conta/acesso'
    },
    {
      slug: 'change-email',
      title: 'Alterar E-mail',
      href: '/minha-conta/acesso'
    },
    {
      slug: 'separator1',
      isSeparator: true
    },
    {
      slug: 'sign-out',
      title: 'Sair',
      href: `${process.env.NEXT_PUBLIC_BJID_URL}/sign-out`
    }
  ]
}

export default getQuickUserMenuItems
