import { validateCPF, validateCep } from 'validations-br'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)

const schema = yup.object().shape({
  avatar_id: yup.string().nullable().required().label('Avatar'),
  name: yup.string().nullable().required().label('Nome completo'),
  username: yup
    .string()
    .nullable()
    .min(3)
    .max(18)
    .matches(
      /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$/,
      'Nome de usuário deve conter somente letras, números e/ou hífens'
    )
    .required()
    .label('Nome de usuários'),
  cpf: yup
    .string()
    .nullable()
    .test('is-cpf', 'Não é um CPF válido', value => validateCPF(value))
    .required()
    .label('CPF'),
  birthdate: yup.date().nullable().required().label('Data de nascimento')
})

export default schema
