import { isMobile } from 'react-device-detect'

import { navbarMenuItems } from '../getNavbarMenuItems'

const isDev = process.env.NODE_ENV == 'development'

export const getCommonItems = () => {
  let items = []
  if (isMobile) items = items.concat(navbarMenuItems)
  items = items.concat([
    {
      slug: 'instances-panel',
      title: 'Painel das Instâncias',
      icon: 'fal fa-browsers',
      href: '/painel-das-instancias'
    },
    {
      slug: 'ejs-panel',
      title: 'Painel EJs',
      icon: 'fal fa-browsers',
      href: '/painel-ejs'
    },
    // {
    //   slug: 'junior-dna',
    //   title: 'DNA Júnior',
    //   icon: 'fal fa-dna',
    //   href: '/educacao/cursos/dna-junior'
    // },
    {
      slug: 'events',
      title: 'Eventos',
      icon: 'fal fa-calendar-star',
      items: [
        {
          slug: 'events-listing',
          title: 'Todos os eventos',
          href: '/eventos'
        },
        {
          slug: 'evnttz-productor',
          title: 'Meus Ingressos',
          href: '/meus-ingressos'
        }
        // {
        //   slug: 'evnttz-locations',
        //   title: 'Alocação EDL24',
        //   href: '/e/alocacao-de-ambientes'
        // }
      ]
    },
    {
      slug: 'network-data',
      title: 'Dados da Rede',
      icon: 'fal fa-chart-network',
      href: '/dados-da-rede'
    },
    // {
    //   slug: 'mej-future',
    //   title: 'PE da Rede 22-24',
    //   icon: 'fal fa-solar-system',
    //   href: '/futuro-do-mej'
    // },
    // {
    //   slug: 'mej-future',
    //   title: 'PE da Rede 22-24',
    //   icon: 'fal fa-solar-system',
    //   href: '/futuro-do-mej'
    // },
    {
      slug: 'mej-ethic',
      title: 'Ética do MEJ',
      icon: 'fal fa-scale-balanced',
      items: [
        {
          slug: 'code-of-ethics',
          title: 'Código de Ética',
          href: 'https://bit.ly/codigodeeticadomej',
          target: '_blank'
        },
        {
          slug: 'ombudsman',
          title: 'Ouvidoria',
          href: 'https://bit.ly/ouvidoriaMEJ',
          target: '_blank'
        }
      ]
    },
    {
      slug: 'tetris-cms',
      title: 'Tetris | Conteúdos',
      icon: 'fal fa-book-open-reader',
      href: '/tetris'
    },
    {
      slug: 'mej-future',
      title: 'Futuro do MEJ',
      icon: 'fal fa-hourglass',
      href: '/futuro-do-mej',
      disabled: !isDev
    },
    {
      slug: 'academy',
      title: 'Academy Abroad',
      icon: 'fal fa-chalkboard-teacher',
      className: 'd-lg-none',
      items: [
        {
          slug: '/academy',
          title: 'Calendário de Mentorias',
          href: '/academy'
        },
        {
          slug: '/academy/mentores',
          title: 'Mentores',
          items: [
            {
              slug: '/academy/mentores',
              title: 'Lista',
              href: '/academy/mentores'
            },
            {
              slug: '/academy/mentorias',
              title: 'Mentorias',
              href: '/academy/mentorias'
            }
          ]
        },

        {
          slug: '/academy/cursos',
          title: 'Cursos',
          href: '/academy/cursos',
          items: [
            {
              slug: '/academy/cursos-todos',
              title: 'Lista',
              href: '/academy/cursos'
            },
            {
              slug: '/academy/cursos-assinaturas',
              title: 'Assinaturas',
              href: '/academy/cursos-assinaturas'
            }
          ]
        },

        {
          slug: '/academy/experiencias',
          title: 'Experiências',
          href: '/academy/experiencias'
        },
        {
          slug: '/academy/portal-carreiras',
          title: 'Vagas',
          href: '/academy/portal-carreiras'
        },
        {
          slug: '/academy/meu-cv',
          title: 'Meu Currículo',
          href: '/academy/meu-cv'
        },
        {
          slug: '/academy/suporte',
          title: 'Suporte',
          href: '/academy/suporte'
        }
      ]
    }
  ])

  return items.filter(item => !(item.disabled == true))
}
