import React, { useEffect, useState } from 'react'

import ls from 'local-storage'
import Swal from 'sweetalert2'
import toastr from 'toastr'

const AccessibilityTopbarNavItem = () => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    const lsActive = ls.get('isAccessibilityActive')
    setActive(lsActive)
  }, [])

  useEffect(() => {
    if (active) {
      if (!document.querySelector('[data-widget=v-libras]')) {
        // const vLibrasEl = document.createElement("div");
        // vLibrasEl.setAttribute("vw", "");
        // vLibrasEl.setAttribute("class", "enabled");
        // vLibrasEl.innerHtml =
        //   '<div vw-access-button className="active"></div><div vw-plugin-wrapper> <div className="vw-plugin-top-wrapper"></div>';
        // document.body.appendChild(vLibrasEl);

        const vLibrasScript = document.createElement('script')
        vLibrasScript.setAttribute('data-widget', 'v-libras')
        vLibrasScript.setAttribute(
          'src',
          'https://vlibras.gov.br/app/vlibras-plugin.js'
        )
        vLibrasScript.onload = () =>
          new window.VLibras.Widget('https://vlibras.gov.br/app')
        ;(document.body || document.head).appendChild(vLibrasScript)
      }

      if (!document.querySelector('[data-widget=user-way]')) {
        const userWayScript = document.createElement('script')
        userWayScript.setAttribute('data-widget', 'user-way')
        userWayScript.setAttribute('data-account', 'oJAzr2eaXu')
        userWayScript.setAttribute('src', 'https://cdn.userway.org/widget.js')
        ;(document.body || document.head).appendChild(userWayScript)
      }
    }
  }, [active])

  const handleAccessiblityBtnClick = event => {
    event.preventDefault()

    if (active) {
      Swal.fire({
        title: 'Acessibilidade',
        text: 'Deseja desativar os menus flutuantes de acessibilidade?',
        showCancelButton: true,
        icon: 'question',
        reverseButtons: true,
        confirmButtonText: 'Sim, desativar',
        cancelButtonText: 'Não'
      }).then(result => {
        if (result.isConfirmed) {
          setActive(false)
          ls.set('isAccessibilityActive', false)

          Swal.fire({
            icon: 'success',
            title:
              'Os menus flutuantes de acessibilidade estão sendo desabilitados'
          }).then(() => document.location.reload(true))
        }
      })
    } else {
      Swal.fire({
        title: 'Acessibilidade',
        text: 'Deseja ativar os menus flutuantes de acessibilidade?',
        showCancelButton: true,
        icon: 'question',
        reverseButtons: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não, cancelar'
      }).then(result => {
        if (result.isConfirmed) {
          setActive(true)
          ls.set('isAccessibilityActive', true)

          Swal.fire({
            icon: 'success',
            title:
              'Os menus flutuantes de acessibilidade estão sendo incorporados e logo aparecerão à direita da sua tela'
          }).then(() => document.location.reload(true))
        }
      })
    }
  }

  return (
    <button
      className={`btn btn-icon btn-icon-white btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px position-relative ${
        active ? 'active' : ''
      }`}
      onClick={handleAccessiblityBtnClick}
    >
      <i className="fal fa-universal-access fs-2"></i>
    </button>
  )
}

export default AccessibilityTopbarNavItem
