const getSealGatherItems = (role, basePath) =>
  _.get(role, 'seal_gather_data', [])
    .filter(gather => gather.condition)
    .map(gather => ({
      slug: `federation-seal-gather-${gather.gather.id}`,
      title: (
        <div className="d-flex justify-content-between w-100 align-items-start">
          <span className="me-2">{gather.name || 'Meu Selo'}</span>
          {gather.open && (
            <span
              className={
                'rounded-circle w-10px h-10px bg-light-primary pulse pulse-primary d-flex align-items-center justify-content-center'
              }
              style={{ marginTop: '5px' }}
            >
              <span className="pulse-ring border-2 w-20px h-20px"></span>
            </span>
          )}
        </div>
      ),
      icon: 'fal fa-badge-check',
      href: `${basePath}/selo/${gather.gather.id}`
    }))

export default getSealGatherItems
