import { Fragment, useEffect, useRef } from 'react'

import _ from 'lodash'
import { DateTime } from 'luxon'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'

import { useRecord, useModalContext } from '@tootz/react-admin'

import { Menu, Transition } from '@headlessui/react'

import { api } from '@/services/api'

import { NotificationsList } from './List'
import { NotificationsModal } from './Modal'

const LAST_ALERTED_NOTIFICATION_ID_COOKIE_KEY =
  'bj.last_alerted_notification_id'

export const NotificationsNavItem = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    LAST_ALERTED_NOTIFICATION_ID_COOKIE_KEY
  ])
  const { showModal } = useModalContext()
  const { record = {}, refetch } = useRecord({
    r: '/portal/notification/messages/dashboard',
    forceIdPresence: false,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchInterval: 30000
    }
  })
  const { unread_size, last_unread, important_unread_size } = record

  const markAllAsRead = async () => {
    try {
      await api.post('/portal/notification/messages/mark_all_as_read')

      refetch()
    } catch (error) {}
  }

  const handleOpenNotification = notification =>
    showModal({
      component: NotificationsModal,
      ...notification,
      onSave: refetch,
      onHide: refetch
    })

  useEffect(() => {
    if (
      last_unread &&
      cookies[LAST_ALERTED_NOTIFICATION_ID_COOKIE_KEY] != last_unread.id
    ) {
      const createdAt = DateTime.fromISO(last_unread?.message?.created_at)
      const now = DateTime.now()
      const diffInDays = now.diff(createdAt, 'days').days

      if (diffInDays <= 7) {
        setCookie(LAST_ALERTED_NOTIFICATION_ID_COOKIE_KEY, last_unread.id, {
          maxAge: 60 * 60 * 24
        })
        toast(
          <div className="d-flex gap-3 align-items-start">
            <i
              className={`fal fs-4 fa-fw text-white p-1 rounded-1 flex-shrink-0 w-25px h-25px d-flex align-items-center justify-content-center ${
                _.get(last_unread, 'message.kind') == 'blocking'
                  ? 'fa-exclamation bg-danger'
                  : 'fa-envelope bg-primary'
              }`}
            ></i>
            <div className="flex-shrink-1">
              <span className="fw-bold fs-6 text-dark">
                {_.get(last_unread, 'message.title', 'Nova mensagem')}
              </span>
              <span
                className={`fw-bold fs-7 d-block ${
                  _.get(last_unread, 'message.kind') == 'blocking'
                    ? 'text-danger'
                    : 'text-primary'
                }`}
              >
                {_.get(last_unread, 'message.kind') == 'blocking'
                  ? 'Chegou uma mensagem importante, clique para ver'
                  : 'Chegou uma nova mensagem pra você, clique para ver'}
              </span>
            </div>
          </div>,
          {
            onClick: () => handleOpenNotification(last_unread),
            className: 'cursor-pointer',
            closeOnClick: true,
            autoClose: 6000
          }
        )
      }
    }
  }, [last_unread])

  return (
    <div className="d-flex align-items-center ms-1 ms-lg-3">
      <Menu as="div" className="position-relative inline-block text-left">
        <div>
          <Menu.Button className="btn btn-icon btn-icon-white btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px position-relative">
            <i className="fal fa-bell fs-2"></i>
            {!!unread_size && (
              <span className="badge badge-circle badge-sm text-white text-center bg-danger position-absolute top-0 end-0">
                {unread_size}
              </span>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="position-fixed position-lg-absolute mx-2 end-0 mt-2 rounded-2 bg-white shadow-lg w-400px w-lg-425px z-index-2">
            <NotificationsList
              unreadSize={unread_size}
              importantUnreadSize={important_unread_size}
              onOpenNotification={handleOpenNotification}
              onMarkAllAsRead={markAllAsRead}
            />
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
