import { Modal } from '@tootz/react-admin/components'

const ChangeRoleViewModal = ({
  activeMenuRoleId,
  roleItems,
  onSelect,
  onHide
}) => {
  return (
    <Modal show={true} centered onHide={onHide}>
      <Modal.Header onHide={onHide}>
        <Modal.Icon className="fal fa-retweet text-primary" />
        <Modal.Title>Alterar visualização</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="h-200px scroll scroll-y">
          {roleItems.map(roleItem => (
            <button
              onClick={() => onSelect(roleItem.id)}
              key={roleItem.id}
              className="btn btn-light-primary mb-4 text-start d-flex justify-content-between align-items-center w-100"
            >
              {roleItem.resource ? roleItem?.resource?.name : 'Time BJ'} -{' '}
              {roleItem.substantive || 'Super Administrador'}
              <i className="fal fa-arrow-right"></i>
            </button>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ChangeRoleViewModal
