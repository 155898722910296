import { useRef, useEffect } from 'react'

import StepWizard from 'react-step-wizard'

import { Modal } from '@tootz/react-admin/components'

import UserProfileValidationAcademicStep from './AcademicStep'
import UserProfileValidationAccountStep from './AccountStep'
import UserProfileValidationPersonalInfoStep from './PersonalInfoStep'
import UserProfileValidationSocialStep from './SocialStep'
import UserProfileValidationTermsStep from './TermsStep'
import UserProfileValidationAddressStep from './AddressStep'

const UserProfileValidationModal = ({ id, show, onHide }) => {
  const formRef = useRef()

  const handleSave = () => {
    if (formRef.current.currentStep < formRef.current.totalSteps) {
      formRef.current.nextStep()
    } else location.reload()
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
    >
      <StepWizard
        ref={formRef}
        isLazyMount
        isHashEnabled={false}
        transitions={{
          enterRight: 'animate__animated animate__fadeIn',
          enterLeft: 'animate__animated animate__fadeIn',
          exitRight: 'animate__animated animate__fadeOut',
          exitLeft: 'animate__animated animate__fadeOut',
          intro: 'animate__animated animate__fadeIn'
        }}
      >
        <UserProfileValidationAccountStep
          my
          onSave={handleSave}
          onHide={onHide}
        />
        <UserProfileValidationAddressStep
          my
          onSave={handleSave}
          onHide={onHide}
        />
        <UserProfileValidationAcademicStep
          my
          onSave={handleSave}
          onHide={onHide}
        />
        <UserProfileValidationPersonalInfoStep
          my
          onSave={handleSave}
          onHide={onHide}
        />
        <UserProfileValidationSocialStep
          my
          onSave={handleSave}
          onHide={onHide}
        />
        <UserProfileValidationTermsStep
          my
          onSave={handleSave}
          onHide={onHide}
        />
      </StepWizard>
    </Modal>
  )
}

export default UserProfileValidationModal
