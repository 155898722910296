import { useEffect } from 'react'

import { useRecords, useModalContext } from '@tootz/react-admin'

import { EvnttzTermsModal } from '@evnttz/Terms/Modal'

export const TopbarTemsNavItem = () => {
  const { showModal, modalIsOpen } = useModalContext()
  const {
    records: terms,
    isLoading,
    refetch
  } = useRecords({
    r: '/users/terms',
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchInterval: 180000
    }
  })
  const notAcceptedTerms =
    !isLoading &&
    terms &&
    terms.length > 0 &&
    terms.filter(term => !term?.acceptance || !term?.acceptance?.accepted_at)
  const notAcceptedTermsForEvntts =
    notAcceptedTerms &&
    notAcceptedTerms.length > 0 &&
    notAcceptedTerms.filter(term => term.termable_type === 'Evntt')

  useEffect(() => {
    if (
      !modalIsOpen &&
      notAcceptedTermsForEvntts &&
      notAcceptedTermsForEvntts.length > 0
    ) {
      showModal({
        component: EvnttzTermsModal,
        terms: notAcceptedTermsForEvntts,
        onSave: refetch
      })
    }
  }, [notAcceptedTermsForEvntts, modalIsOpen])

  return null
}
