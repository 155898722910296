import { useState, useRef, useEffect } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'

import { useRecordsInfinite } from '@tootz/react-admin'

import { Menu } from '@headlessui/react'

import { NotificationsLink } from './Link'

export const NotificationsList = ({
  unreadSize,
  importantUnreadSize,
  onOpenNotification,
  onMarkAllAsRead
}) => {
  const listRef = useRef()
  const [listView, setListView] = useState(unreadSize > 0 ? 'not-read' : 'all')
  const {
    records: notifications,
    fetchNextPage,
    hasNextPage,
    isEmpty,
    isLoading
  } = useRecordsInfinite({
    r: '/portal/notification/messages/my',
    queryParams: {
      filter: {
        read_at_null: listView == 'not-read' ? true : null,
        message_kind_eq: listView == 'important' ? 'blocking' : null
      }
    }
  })

  let listViews = [
    { slug: 'all', name: 'Todas' },
    { slug: 'important', name: 'Importantes', total: importantUnreadSize }
  ]

  if (unreadSize > 0)
    listViews = [
      { slug: 'not-read', name: 'Não lidas', total: unreadSize },
      { slug: 'important', name: 'Importantes', total: importantUnreadSize },
      { slug: 'all', name: 'Arquivo' }
    ]

  useEffect(() => {
    listRef?.current && listRef.current.scrollTo(0, 0)
  }, [listView])

  useEffect(() => {
    listRef?.current && listRef.current.scrollTo(0, 0)
  }, [listView])

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between px-8 pt-8">
        <span className="fw-bolder text-center fs-3">Notificações</span>
        {unreadSize > 0 && (
          <span
            className="fw-bold fs-7 text-dark text-hover-info cursor-pointer"
            onClick={onMarkAllAsRead}
          >
            <i className="fal fa-check-double pe-1 text-dark"></i> Marcar todas
            como lido
          </span>
        )}
      </div>
      <div className="d-flex gap-4 w-100 px-8 mt-8 position-relative">
        <div className="position-absolute bottom-0 start-0 w-100 border-bottom border-2"></div>
        {listViews.map(lv => (
          <span
            key={lv.slug}
            className={`align-items-center d-flex fs-7  gap-2 pb-2 position-relative px-2 border-2 border-bottom ${
              listView == lv.slug ? 'border-dark fw-bold ' : 'cursor-pointer'
            }`}
            onClick={() => setListView(lv.slug)}
          >
            <span>{lv.name}</span>
            {lv.total > 0 && (
              <div
                className={`badge badge-circle badge-sm ${
                  listView == lv.slug
                    ? 'badge-dark'
                    : 'bg-white border border-gray-400 text-gray-400'
                }`}
              >
                {lv.total}
              </div>
            )}
          </span>
        ))}
      </div>
      <InfiniteScroll
        dataLength={notifications.length}
        height={450}
        className="d-flex flex-column gap-0"
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={
          <div className="d-flex w-100 justify-content-center align-items-center p-4">
            <i className="fal fa-spinner-third fa-spin me-3 fs-4"></i>
            <span className="text-gray-500 fs-4">Carregando registros...</span>
          </div>
        }
        endMessage={
          <>
            {!isEmpty && !isLoading && !hasNextPage && (
              <div className="d-flex w-100 justify-content-center align-items-center p-4">
                <i className="fal fa-circle-check me-3 fs-4 text-gray-800 text-success"></i>
                <span className="text-gray-800 fs-7 fw-semibold">
                  Todas as mensagens foram carregadas
                </span>
              </div>
            )}

            {isEmpty && (
              <div className="d-flex w-100 justify-content-center align-items-center p-4">
                <i className="fal fa-empty-set text-warning me-3 fs-4"></i>
                <span className="text-gray-800 fs-7 fw-semibold">
                  Nenhuma mensagem encontrada
                </span>
              </div>
            )}
            {isLoading && (
              <div className="d-flex w-100 justify-content-center align-items-center p-4">
                <i className="fal fa-spinner-third fa-spin text-info me-3 fs-4"></i>
                <span className="text-gray-800 fs-7 fw-semibold">
                  Carregando...
                </span>
              </div>
            )}
          </>
        }
      >
        {notifications.map(notification => (
          <Menu.Item key={notification.id}>
            <div>
              <NotificationsLink
                onOpen={() => onOpenNotification(notification)}
                {...notification}
              />
            </div>
          </Menu.Item>
        ))}
      </InfiniteScroll>
    </div>
  )
}
