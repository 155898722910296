module.exports = {
  RELEASE_YEAR: 2016,
  BRAND_NAME: 'Portal BJ',
  BRAND_SHORT_NAME: 'Portal BJ',
  SITE_URL: 'https://portal.brasiljunior.org.br',
  SITE_DESCRIPTION:
    'Confederação Brasileira de Empresas Juniores é a instância que representa as empresas juniores brasileiras, impulsionando a vivência empresarial que elas proporcionam e legitimando-as à sociedade.',
  TWITTER_NAME: '@bjnoinsta',
  FACEBOOK_NAME: '@brasiljunior'
}
