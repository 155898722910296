import { useState, useEffect } from 'react'

import _ from 'lodash'
import { useRouter } from 'next/router'
import store from 'store2'

import ChangeRoleViewModal from './navigation/ChangeRoleViewModal'
import { getCommonItems } from './navigation/getCommonItems'
import getRolesItems from './navigation/getRolesItems'

const ACTIVE_ROLE_ID_KEY = 'bj.active-role-id'

const useAsideMenuItems = (user, ability, setShowModal) => {
  const router = useRouter()
  const [activeMenuRoleId, setActiveMenuRoleId] = useState(
    store.get(ACTIVE_ROLE_ID_KEY)
  )
  const commonItems = getCommonItems(user, ability)
  const roleItems = getRolesItems(user, ability, setShowModal)
  const activeMenuRoleItems =
    _.find(roleItems, { roleId: activeMenuRoleId }) || roleItems[0]
  let items = [...commonItems]

  useEffect(() => {
    store.set(ACTIVE_ROLE_ID_KEY, activeMenuRoleId)
  }, [activeMenuRoleId])

  if (activeMenuRoleItems) {
    if (!activeMenuRoleId) {
      store.set(ACTIVE_ROLE_ID_KEY, activeMenuRoleItems?.roleId)
    }

    items = [
      ...items,
      {
        slug: 'role-title',
        component: (
          <div className="menu-item admin">
            <div className="menu-content d-flex align-items-center justify-content-between pb-2 pt-8">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                {activeMenuRoleItems.title}
              </span>
              {roleItems.length > 1 && (
                <span
                  className="btn btn-active-light btn-icon btn-sm"
                  onClick={() =>
                    setShowModal({
                      component: ChangeRoleViewModal,
                      roleItems,
                      activeMenuRoleId,
                      onSelect: id => {
                        setActiveMenuRoleId(id)
                        setShowModal(null)
                        router.push('/')
                      }
                    })
                  }
                >
                  <i className="fal fa-retweet"></i>
                </span>
              )}
            </div>
          </div>
        )
      },
      ...activeMenuRoleItems.items
    ]
  }

  return items
}

export default useAsideMenuItems
