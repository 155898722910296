import {
  useResourceRecordContext,
  withResourceRecord
} from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'

import { getConfig } from '../config'
import UserSocialFields from '../Fields/Social'

import UserProfileValidationModalFooter from './StepFooter'

const UserProfileValidationSocialStep = ({
  onSave,
  onHide,
  currentStep,
  previousStep,
  totalSteps
}) => {
  const { Form, isSubmitting } = useResourceRecordContext()

  return (
    <Form validationSchema={UserSocialFields.validationSchema} onSave={onSave}>
      <Modal.Header>
        <Modal.Icon className="fal fa-hashtag text-primary" />
        <Modal.Title>Redes & Contatos</Modal.Title>
        <Modal.Subtitle>
          Compartilhe suas redes sociais e informações de contato com a Rede e
          esteja sempre em conectado
        </Modal.Subtitle>
      </Modal.Header>{' '}
      <Modal.Body className="min-h-450px d-flex flex-column justify-content-center">
        <UserSocialFields />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <UserProfileValidationModalFooter
          onHide={onHide}
          previousStep={previousStep}
          currentStep={currentStep}
          totalSteps={totalSteps}
          isSubmitting={isSubmitting}
        />
      </Modal.Footer>
    </Form>
  )
}

export default withResourceRecord(UserProfileValidationSocialStep, getConfig)
