import axios from 'axios'
import Cookies from 'js-cookie'
import _ from 'lodash'
import qs from 'qs'
import Swal from 'sweetalert2'

import {
  getErrorMessage,
  showErrorAlert,
  getQuery,
  cookie
} from '@tootz/react-admin'

axios.defaults.paramsSerializer = params =>
  qs.stringify(params, { arrayFormat: 'brackets' })

const api = axios.create({
  // withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  headers: { accept: 'application/json' }
})

api.interceptors.request.use(
  async config => {
    const cookiedToken = Cookies.get('token')
    if (cookiedToken) config.headers.Authorization = `Bearer ${cookiedToken}`
    return config
  },
  error => Promise.reject(error)
)

api.interceptors.response.use(
  response => response,
  async error => {
    if (403 === _.get(error, 'response.status')) {
      Swal.fire({
        icon: 'info',
        title: 'Acesso não autorizado!',
        text: 'Seu perfil de acesso não permite visualização desta página, caso entenda que isto é um erro, entre em contato com a equipe técnica para verificação.',
        type: 'warning',
        showCancelButton: false,
        showCloseButton: false,
        confirmButtonColor: '#197cc1',
        confirmButtonText: 'Continuar',
        closeOnConfirm: false,
        allowOutsideClick: false
      }).then(result => {
        window.location = '/'
      })
    } else if (401 === _.get(error, 'response.status')) {
      try {
        const refreshToken = Cookies.get('refreshToken')
        const response = await axios.post(
          `${process.env.NEXT_PUBLIC_API_BASE_URL.replace(
            '/v1',
            ''
          )}/oauth/token`,
          {
            refresh_token: refreshToken,
            grant_type: 'refresh_token'
          }
        )
        const { access_token: token, expires_in } = response.data

        Cookies.set('token', token, {
          expires: expires_in,
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN
        })

        error.config.headers.Authorization = `Bearer ${token}`
        return axios.request(error.config)
      } catch (error) {
        Swal.fire({
          icon: 'info',
          title: 'Sessão expirada!',
          text: 'Sua sessão expirou. Gostaria de ser redirecionado à página de login?',
          type: 'warning',
          showCancelButton: false,
          showCloseButton: false,
          confirmButtonColor: '#197cc1',
          confirmButtonText: 'Sim',
          closeOnConfirm: false,
          allowOutsideClick: false
        }).then(result => {
          if (result.isConfirmed)
            window.location = `${process.env.NEXT_PUBLIC_BJID_URL}/sign-out?callbackUrl=${window.location.href}`
        })
      }
    } else return Promise.reject(error)
  }
)

// Self API
const selfApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SELF_API_BASE_URL
})

// Tootz Payments API
const paymentsApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_TOOTZ_PAYMENTS_API_BASE_URL
})

export {
  api,
  selfApi,
  paymentsApi,
  // fetcher,
  // useRecords,
  // useRecord,
  getErrorMessage,
  showErrorAlert,
  getQuery
}
