import { DateTime } from 'luxon'
import TextTruncate from 'react-text-truncate'
import ReactTimeAgo from 'react-time-ago'

export const NotificationsLink = ({ message, read_at, read, onOpen }) => {
  const createdAt = DateTime.fromISO(message.created_at)
  const old = createdAt.diffNow().as('days') < -7

  return (
    <div
      className="py-6 cursor-pointer border-bottom border-gray-300 px-10 position-relative"
      onClick={onOpen}
    >
      {!read_at && (
        <>
          <div className="bg-light-info opacity-75 position-absolute top-0 start-0 w-100 h-100"></div>

          <span className="badge h-10px w-10px badge-circle bg-info z-index-1 position-absolute top-0 end-0 m-5"></span>
        </>
      )}

      <div className="position-relative d-flex flex-column">
        <span className="fs-6 text-black mb-1 d-block text-hover-info fw-bold">
          {message.title}
        </span>
        <div className="d-flex justify-content-between align-items-center fs-8 text-gray-600 mb-4">
          {old ? (
            <span className="text-capitalize">
              {createdAt.toFormat('cccc HH:mm')}
            </span>
          ) : (
            <ReactTimeAgo
              date={createdAt.toJSDate()}
              className="text-capitalize"
            />
          )}
          <span className="text-capitalize">
            {createdAt.toFormat('LLL dd, yyyy')}
          </span>
        </div>
        {!!message.content && (
          <span className="border border-gray-300 rounded-2 d-block py-2 px-3 text-black fs-7">
            <TextTruncate
              line={3}
              textTruncateChild={
                <span className="fw-bolder border-hover border-info border-bottom text-dark">
                  leia na íntegra
                </span>
              }
              text={message.content}
            />
          </span>
        )}
        {message?.link_url && (
          <div className="mt-4">
            <button
              type="button"
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()

                window.open(message.link_url, '_blank')
              }}
              className="btn btn-info btn-sm px-3 py-2 fs-8"
            >
              {(message.link_title || 'Acessar').replace('https://', '')}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
