import { useState } from 'react'

import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import Link from 'next/link'

import { showErrorAlert, useSessionContext } from '@tootz/react-admin'
import { Card } from '@tootz/react-admin/components'
import {
  FormControl,
  FormRadio,
  FormChecklist,
  ErrorMessage
} from '@tootz/react-admin/form'

import SurveyAnswerFormFooter from './FormFooter'

import { api } from '@/services/api'

const SurveysAnswerForm = ({
  id,
  surveyId,
  item,
  reply,
  kind,
  step,
  year,
  onSave,
  onHide,
  currentStep,
  previousStep,
  totalSteps
}) => {
  const { user } = useSessionContext()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const methods = useForm({
    defaultValues: reply
  })
  const onSubmit = async data => {
    setIsSubmitting(true)

    try {
      let answer = { answer: data.answer }
      if (
        item.actable_type == 'Survey::MultipleChoiceQuestion' &&
        item.multiple_choice_question.accepts_multiple == true
      )
        answer = { answer_array: data.answer_array }

      await api({
        url: `/portal/survey/surveys/${surveyId}/replies${
          reply ? '/' + reply.id : ''
        }`,
        method: reply ? 'PUT' : 'POST',
        data: {
          record: {
            ...answer,
            answerable_id: user.id,
            answerable_type: 'User',
            item_id: item.id
          }
        }
      })

      setIsSubmitting(false)
      onSave()
    } catch (error) {
      setIsSubmitting(false)
      showErrorAlert(error)
    }
  }

  const Field = () => {
    if (
      item.actable_type == 'Survey::MultipleChoiceQuestion' &&
      item.multiple_choice_question.accepts_multiple == false
    )
      return (
        <FormRadio
          name="answer"
          options={item.multiple_choice_question.alternatives.map(
            alternative => ({
              label: alternative.description,
              value: alternative.id
            })
          )}
          required={item.multiple_choice_question.is_required}
        />
      )
    if (
      item.actable_type == 'Survey::MultipleChoiceQuestion' &&
      item.multiple_choice_question.accepts_multiple == true
    )
      return (
        <FormChecklist
          name="answer_array"
          options={item.multiple_choice_question.alternatives.map(
            alternative => ({
              label: alternative.description,
              value: alternative.id
            })
          )}
          required={item.multiple_choice_question.is_required}
        />
      )
    if (item.actable_type == 'Survey::TextQuestion')
      return (
        <FormControl
          name="answer"
          as="textarea"
          required={item.text_question.text_question}
          size="lg"
        />
      )
    if (item.actable_type == 'Survey::RankingQuestion')
      return (
        <FormRadio
          name="answer"
          options={[
            { label: 1, value: 1 },
            { label: 2, value: 2 },
            { label: 3, value: 3 },
            { label: 4, value: 4 },
            { label: 5, value: 5 }
          ]}
          inline
          required={item.ranking_question.text_question}
          size="lg"
        />
      )
  }

  return (
    <Card>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card.Header noBorder className="pt-6">
            <div className="d-flex flex-column">
              <Card.Title>{item.title}</Card.Title>
              <span className="fw-bold text-gray-600">
                {item?.ranking_question?.description ||
                  item?.multiple_choice_question?.description ||
                  item?.text_question?.description}
              </span>
            </div>
          </Card.Header>
          <Card.Body>
            <Field name="answer" required />
          </Card.Body>
          <Card.Footer>
            <SurveyAnswerFormFooter
              currentStep={currentStep}
              previousStep={previousStep}
              totalSteps={totalSteps}
              isSubmitting={isSubmitting}
            />
          </Card.Footer>
        </form>
      </FormProvider>
    </Card>
  )
}

export default SurveysAnswerForm
