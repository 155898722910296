import * as yup from 'yup'
import { pt } from 'yup-locale-pt'
import { validateCep } from 'validations-br'

yup.setLocale(pt)

const schema = yup.object().shape({
  locations: yup
    .array()
    .of(
      yup.object().shape({
        zip_code: yup
          .string()
          .test('is-cep', 'Não é um CEP válido', value => validateCep(value))
          .required()
          .label('CEP'),
        street: yup.string().required().label('Rua'),
        number: yup.string().required().label('Nº'),
        state: yup.string().required().label('UF'),
        district: yup.string().required().label('Bairro'),
        city: yup.string().required().label('Cidade'),
        complement: yup.string().nullable().label('Complemento')
      })
    )
    .nullable()
    .min(1)
    .required()
    .label('Endereços')
})

export default schema
