import _ from 'lodash'

import { defineAbility } from '@casl/ability'

import {
  User,
  Federation,
  Core,
  Ej,
  IES,
  CustomEj,
  Contract,
  ConnectionProduct,
  ConnectionProductCategory,
  CommunitiesPost,
  Event,
  SealGather
} from './entities'

const isDev = process.env.NODE_ENV == 'development'

const useAbility = (user, activeRoleId) =>
  defineAbility(can => {
    if (!user) return

    can('manage', 'User', { id: user.id })
    can('manage', 'CommunitiesPost', { user_id: user.id })

    let roles = _.get(user, 'roles', [])
    if (activeRoleId && roles.findIndex(role => role.id == activeRoleId) !== -1)
      roles = roles.filter(role => role.id == activeRoleId)

    roles.forEach(role => {
      const roleName = _.get(role, 'name')
      const resourceType = _.get(role, 'resource_type')
      const resourceId = _.get(role, 'resource_id')

      const systemAdmin =
        ['system_admin'].includes(roleName) &&
        (!resourceType || resourceType === 'Brasil Júnior')
      const admin =
        ['admin'].includes(roleName) &&
        (!resourceType || resourceType === 'Brasil Júnior')
      const adminEvents =
        ['admin_events'].includes(roleName) &&
        (!resourceType || resourceType === 'Brasil Júnior')

      if (systemAdmin || admin) {
        if (systemAdmin || isDev) {
          can('manage', 'System')
          can('manage', 'all')
        }

        // can('manage', 'all')
        can('manage', 'Partner')
        can('manage', 'University')
        can('manage', 'Federation')
        can('manage', 'Core')
        can(['read', 'update', 'delete'], 'Ej')
        can('manage', 'User')

        can('audit', 'Contract')
        can('audit', 'InstanceContract')

        can('manage', 'ConnectionProduct')
        can('manage', 'CommunitiesPost')

        can('manage', 'Event')
        can('audit', 'SealGather')

        // Modules
        can('manage', 'Courses')
        can('manage', 'Events')
        can('manage', 'ConnectionProducts')
        can('manage', 'Evnttz')
        can('manage', 'GoalGathering')
      } else if (adminEvents) {
        can('manage', 'Event')

        // Modules
        can('manage', 'Events')
        can('manage', 'Evnttz')
      } else if (resourceType === 'Partner') {
        const partnerManager = ['admin'].includes(roleName)

        if (partnerManager) can('manage', 'Partner', { id: resourceId })
      } else if (resourceType === 'University') {
        const universityManager = [
          'admin',
          'sub_dean',
          'dean',
          'ej_advisor',
          'department_boss',
          'course_coordinator',
          'teacher',
          'director'
        ].includes(roleName)

        if (universityManager) {
          can('manage', 'University', { id: resourceId })
          can('update', 'SealGather', { ['sealer.id']: resourceId })
        }
      } else if (resourceType === 'Federation') {
        const federationManager = ['admin', 'director'].includes(roleName)

        if (federationManager) {
          can('manage', 'Federation', { id: resourceId })
          can('manage', 'Core', { federation_id: resourceId })
          can(['read', 'update'], 'Ej', { federation_id: resourceId })
          can('audit', 'SealGather', { ['sealer.federation_id']: resourceId })
          can('update', 'SealGather', { ['sealer.id']: resourceId })

          can('create', 'ConnectionProduct')
          can('manage', 'ConnectionProduct', {
            connection_productable_id: resourceId,
            connection_productable_type: 'Federation'
          })
          can('manage', 'Event', {
            eventable_id: resourceId,
            eventable_type: 'Federation'
          })
          can('manage', 'Evnttz')
        }
      } else if (resourceType === 'Core') {
        const coreManager = ['admin'].includes(roleName)

        if (coreManager) {
          can('manage', 'Core', { id: resourceId })
          can(['read', 'update'], 'Ej', { core_ids: { $all: [resourceId] } })
          can('audit', 'SealGather', {
            ['sealer.core_ids']: { $all: [resourceId] }
          })
          can('update', 'SealGather', { ['sealer.id']: resourceId })

          can('create', 'ConnectionProduct')
          can('manage', 'ConnectionProduct', {
            connection_productable_id: resourceId,
            connection_productable_type: 'Core'
          })
          can('manage', 'Event', {
            eventable_id: resourceId,
            eventable_type: 'Core'
          })
          can('manage', 'Evnttz')
        }
      } else if (resourceType === 'Ej') {
        const ejManager = ['admin', 'director'].includes(roleName)
        // const ejMember = ['member', 'trainee'].includes(roleName)
        // const ejPostJunior = ['post_junior'].includes(roleName)

        can('read', 'Ej', { id: resourceId })
        can('manage', 'Evnttz')

        if (ejManager) {
          can('update', 'Ej', { id: resourceId })
          can('validate', 'Ej', { id: resourceId })
          can('update', 'SealGather', { ['sealer.id']: resourceId })
        }
      }
    })
  })

export default useAbility
export {
  User,
  IES,
  Federation,
  Core,
  Ej,
  CustomEj,
  Contract,
  ConnectionProduct,
  ConnectionProductCategory,
  CommunitiesPost,
  Event,
  SealGather
}
