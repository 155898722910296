import _ from 'lodash'

import getAdminItems from './getAdminItems'
import getCoreItems from './getCoreItems'
import getEjItems from './getEjItems'
import getFederationItems from './getFederationItems'
import getIESItems from './getIESItems'

const titlesByResourceType = {
  'Hire::Maintainer': 'Minha empresa',
  Partner: 'Minha empresa',
  University: 'Minha IES',
  Federation: 'Minha Federação',
  Core: 'Meu núcleo',
  Ej: 'Minha EJ'
}

const itemsFnByResourceType = {
  'Hire::Maintainer': () => [],
  Partner: () => [],
  University: getIESItems,
  Federation: getFederationItems,
  Core: getCoreItems,
  Ej: getEjItems
}

const getRolesItems = (user, ability, setShowModal) => {
  let items = []
  const roles = _.get(user, 'roles')

  if (!roles || !_.isArray(roles) || !roles.length) return items

  items = roles.map(role => {
    let getItemsFn = _.get(itemsFnByResourceType, role.resource_type, () => [])

    if (
      role.name.includes('admin') &&
      role.resource_type === null &&
      role.resource_id === null
    )
      getItemsFn = getAdminItems

    const item = {
      roleId: role.id,
      title: _.get(titlesByResourceType, role.resource_type, 'Administração'),
      items: getItemsFn(role, ability, setShowModal),
      ...role
    }

    return item
  })

  return items
}

export default getRolesItems
