import React, { useState } from 'react'

import toastr from 'toastr'
import moment from 'moment'

import { Modal } from '@tootz/react-admin/components'

import { api } from '@/services/api'

const NotificationModal = ({ survey, handleClose }) => {
  const [submitting, setSubmitting] = useState(false)

  const handleSetRead = e => {
    e.preventDefault()
    setSubmitting(true)

    api
      .post('/admin/survey/surveys/read', {
        survey_ids: [survey.survey.id]
      })
      .then(() => {
        toastr.success('Pesquisa marcada como lida', 'Feito!')
      })
      .catch(error => console.log(error))
      .finally(() => {
        setSubmitting(false)
        handleClose(survey.id)
      })
  }

  const handleAnswer = () => {
    window.location.href = `/pesquisas/${survey.survey.id}/responder`
  }

  const isBlocking = !survey.answered && survey.survey.is_required

  return (
    <Modal
      size="md"
      show={!!survey}
      backdrop={isBlocking ? 'static' : true}
      keyboard={!isBlocking}
      onHide={() => handleClose(survey.id)}
      className={`${survey.survey.kind}`}
      backdropClassName={`${survey.survey.kind}`}
    >
      <Modal.Header onHide={!isBlocking ? () => handleClose(survey.id) : null}>
        <Modal.Icon className="fal fa-3x fa-bullhorn" />
        <Modal.Title>Nova Pesquisa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="badge badge-success modal-date-badge mb-4">
          {moment(survey.created_at).format('DD/MM/YYYY')}
        </span>
        <h4>{survey.survey.name}</h4>
        <h6>{survey.survey.subtitle}</h6>
        <br />
        {survey.publish_in && survey.until && (
          <p>
            <strong>Período:</strong> {survey.survey.publish_in} -{' '}
            {survey.survey.until}
          </p>
        )}
        {survey.survey.estimated_time && (
          <p>
            <strong>Tempo de estimado:</strong> {survey.survey.estimated_time}
          </p>
        )}
        {!!survey.survey.instructions && (
          <p>
            <strong>Instruções:</strong>
            <span
              dangerouslySetInnerHTML={{ __html: survey.survey.instructions }}
            />
          </p>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        {!survey.read && survey.answered && (
          <span className="text-success mt-1 me-auto">
            <i className="far fa-check-circle me-1"></i>
            {`Respondido em ${moment(survey.answered_at).format(
              'DD/MM/YYYY [às] HH:mm'
            )}`}
          </span>
        )}
        {survey.read && !survey.answered && (
          <span className="text-success mt-1 me-auto">
            <i className="far fa-check-circle me-1"></i>
            {`Lido em ${moment(survey.read_at).format(
              'DD/MM/YYYY [às] HH:mm'
            )}`}
          </span>
        )}
        {!survey.read && !survey.survey.is_required && (
          <a
            href="#"
            className="btn"
            onClick={handleSetRead}
            disabled={submitting}
          >
            Deixar para depois
          </a>
        )}

        {survey.answered ? (
          <>
            <a
              href={`/pesquisas/${survey.survey.id}/responder`}
              className="btn btn-light-primary"
              onClick={() => handleClose(survey.id)}
            >
              Editar respostas
            </a>
            <button
              className="btn btn-primary"
              onClick={() => handleClose(survey.id)}
            >
              Fechar
            </button>
          </>
        ) : (
          <a
            href={`/pesquisas/${survey.survey.id}/responder`}
            className="btn btn-primary"
            onClick={() => handleClose(survey.id)}
          >
            Responder
          </a>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default NotificationModal
