import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)

const schema = yup.object().shape({
  not_junior: yup
    .bool()
    .nullable()
    .label('Nunca cursei em uma Instituição de Ensino'),
  junior: yup.object().when('not_junior', {
    is: false,
    then: yup.object().shape({
      university_id: yup.string().nullable().required().label('Universidade'),
      course_id: yup.string().nullable().required().label('Curso'),
      semester: yup.string().nullable().required().label('Semestre')
    })
  })
})

export default schema
