import { useResourceRecordContext } from '@tootz/react-admin'
import { LoadingAlert, ErrorAlert } from '@tootz/react-admin/components'

const UserProfileValidationModalFooter = ({
  onHide,
  isSubmitting,
  previousStep,
  currentStep,
  totalSteps
}) => {
  const { isLoading, isError } = useResourceRecordContext()

  if (isLoading) return <></>
  if (isError) return <ErrorAlert />
  return (
    <>
      {currentStep == 1 ? (
        // <button
        //   type="button"
        //   className="btn btn-light me-4"
        //   onClick={onHide}
        //   disabled={isSubmitting}
        // >
        //   Cancelar
        // </button>
        <></>
      ) : (
        <button
          type="button"
          className="btn btn-light me-4"
          onClick={previousStep}
        >
          Voltar
        </button>
      )}

      {currentStep >= totalSteps ? (
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn btn-success"
        >
          <i className="fal fa-check"></i>
          {isSubmitting ? 'Finalizando...' : 'Finalizar'}
        </button>
      ) : (
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn btn-primary"
        >
          {isSubmitting ? 'Salvando...' : 'Salvar e avançar'}{' '}
          <i className="fal fa-arrow-right pe-0 ps-2"></i>
        </button>
      )}
    </>
  )
}

export default UserProfileValidationModalFooter
