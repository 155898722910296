import { useEffect } from 'react'

import _ from 'lodash'
import { DateTime } from 'luxon'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useFormContext, useFieldArray } from 'react-hook-form'
import toastr from 'toastr'

import { useResourceRecordContext } from '@tootz/react-admin'
import {
  FormGroup,
  FormControl,
  InputGroup,
  InputMask,
  Select,
  DateInput,
  ErrorMessage,
  NestedFields,
  QuillTextEditor,
  ImageInput,
  ReferenceFields
} from '@tootz/react-admin/form'

import { getConfig } from '../../config'

import validationSchema from './validationSchema'

const UserPersonalInfoFields = () => {
  const { pronouns, genders, sexualOrientations, colors, pcd, familyIncomes } =
    getConfig()

  const profileInfos = [
    { slug: 'gender', title: 'Identidade de gênero', items: genders },
    {
      slug: 'sexual_orientation',
      title: 'Orientação sexual',
      items: sexualOrientations
    },
    { slug: 'color', title: 'Cor/Raça', items: colors },
    { slug: 'pcd', title: 'PcD', items: pcd },
    { slug: 'family_income', title: 'Renda familiar', items: familyIncomes }
  ]

  return (
    <>
      <FormGroup
        name="pronoun"
        label="Pronome"
        control={Select}
        options={pronouns.map(({ title, slug }) => ({
          label: title,
          value: slug
        }))}
        solid
        required
      />
      <ReferenceFields
        value={['user_profile', 'user_profile.user_personal_info']}
      />
      {profileInfos.map(({ slug, title, items }) => (
        <FormGroup
          key={slug}
          name={`user_profile.user_personal_info.${slug}`}
          label={title}
          control={Select}
          options={items.map(({ title, slug }) => ({
            label: title,
            value: slug
          }))}
          required
          solid
        />
      ))}
    </>
  )
}

UserPersonalInfoFields.validationSchema = validationSchema

export default UserPersonalInfoFields
