import styles from '../styles.module.scss'

const UserProfileValidationModalFooter = ({
  onHide,
  isSubmitting,
  previousStep,
  currentStep,
  totalSteps
}) => {
  return (
    <>
      {currentStep == 1 ? (
        <button
          type="button"
          className={`btn me-4 ${styles.BtnLight}`}
          onClick={onHide}
          disabled={isSubmitting}
        >
          Fechar
        </button>
      ) : (
        <button
          type="button"
          onClick={previousStep}
          className={`btn me-4 ${styles.BtnLight}`}
        >
          Voltar
        </button>
      )}
      {currentStep >= totalSteps && (
        <button
          type="submit"
          disabled={isSubmitting}
          className={`btn btn-success ${styles.BtnSuccess}`}
        >
          <i className="fal fa-check"></i>
          {isSubmitting
            ? 'Consolidando metas...'
            : 'Consolidar metas e finalizar'}
        </button>
      )}
      {currentStep < totalSteps && currentStep !== 1 && (
        <button
          type="submit"
          disabled={isSubmitting}
          className={`btn flex-grow-1 ${styles.BtnPrimary}`}
        >
          {isSubmitting ? 'Salvando...' : 'Salvar e avançar'}{' '}
          <i className="fal fa-arrow-right text-white pe-0 ps-2"></i>
        </button>
      )}
      {currentStep < totalSteps && currentStep == 1 && (
        <button
          type="submit"
          disabled={isSubmitting}
          className={`btn flex-grow-1 px-10 ${styles.BtnPrimary}`}
        >
          {isSubmitting ? 'Iniciando...' : 'Iniciar'}{' '}
          <i className="fal fa-arrow-right text-white pe-0 ps-2"></i>
        </button>
      )}
    </>
  )
}

export default UserProfileValidationModalFooter
