import _ from 'lodash'

export const basePath = '/bj/termos'
export const icon = 'file-signature'
export const humanName = {
  singular: 'Termo',
  plural: 'Termos'
}
export const termKinds = [
  { slug: 'terms_of_use', name: 'Termos de Uso' },
  { slug: 'privacy_policy', name: 'Política de Privacidade' },
  {
    slug: 'data_retention',
    name: 'Política de Retenção e Descarte de Dados'
  },
  { slug: 'cookies_policy', name: 'Política de Cookies' },
  { slug: 'newsletter', name: 'Newsletter' },
  { slug: 'other', name: 'Outro' }
]
export const getTermKind = slug => _.find(termKinds, { slug })
export const contentTypes = [
  { slug: 'file', name: 'Arquivo' }
  // { slug: 'text', name: 'Texto' },
  // { slug: 'newsletter', name: 'Newsletter' }
]
export const getContentType = slug => _.find(contentTypes, { slug })
export const termableTypes = [
  { slug: 'Evntt', name: 'Eventos' },
  { slug: 'Events::Event', name: 'Eventos (legado)' }
]
export const getTermableType = slug => _.find(termableTypes, { slug })
export const getConfig = (props = {}) => {
  const { my } = props

  const config = {
    basePath,
    apiBasePath: my ? '/users/terms' : '/portal/terms',
    icon,
    humanName,
    header: {
      toolbarLinks: [
        {
          text: 'Adicionar',
          url: '/termos/novo',
          className: 'btn btn-light-primary btn-sm'
        }
      ]
    },
    contentTypes,
    getContentType,
    termKinds,
    getTermKind,
    termableTypes,
    getTermableType
  }

  return config
}
