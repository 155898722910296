import {
  withResourceRecord,
  useResourceRecordContext
} from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'

import UserAddressFields from '../Fields/Address/Simple'
import { getConfig } from '../config'

import UserProfileValidationModalFooter from './StepFooter'

const UserProfileValidationAddressStep = ({
  onSave,
  onHide,
  currentStep,
  previousStep,
  totalSteps
}) => {
  const { Form, isSubmitting } = useResourceRecordContext()

  return (
    <Form validationSchema={UserAddressFields.validationSchema} onSave={onSave}>
      <Modal.Header>
        <Modal.Icon className="fad fa-map-location-dot text-primary" />
        <Modal.Title>Endereço</Modal.Title>
        <Modal.Subtitle>
          Para uma melhor experiência, informe seu endereço
        </Modal.Subtitle>
      </Modal.Header>{' '}
      <Modal.Body className="min-h-450px d-flex flex-column justify-content-center">
        <UserAddressFields />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <UserProfileValidationModalFooter
          onHide={onHide}
          previousStep={previousStep}
          currentStep={currentStep}
          totalSteps={totalSteps}
          isSubmitting={isSubmitting}
        />
      </Modal.Footer>
    </Form>
  )
}

export default withResourceRecord(UserProfileValidationAddressStep, getConfig)
