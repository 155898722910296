import { useRef } from 'react'

import { useRouter } from 'next/router'
import StepWizard from 'react-step-wizard'

import { useAbilityContext, useSessionContext } from '@tootz/react-admin'
import {
  useRecords,
  useResourceRecordContext,
  withResourceRecord
} from '@tootz/react-admin'
import { LoadWrapper, Modal } from '@tootz/react-admin/components'

import getConfig from '../config'

import SurveyAnswerForm from './Form'

const SurveyAnswer = ({ id }) => {
  const { user } = useSessionContext()
  const router = useRouter()
  const formRef = useRef()
  const ability = useAbilityContext()
  const canManageAll = ability.can('manage', 'All')
  const {
    record: survey,
    config: { apiBasePath }
  } = useResourceRecordContext()
  const { records: items } = useRecords(`${apiBasePath}/${survey?.id}/items`, {
    pagination: { perPage: 999 }
  })
  const {
    records: replies,
    refetch: refetchReplies,
    isLoading: isLoadingReplies,
    isError: isErrorReplies
  } = useRecords(`${apiBasePath}/${survey?.id}/replies`, {
    pagination: { perPage: 999 },
    filter: { user_id_eq: user.id }
  })
  const handleSave = () => {
    refetchReplies()

    if (formRef.current.currentStep < formRef.current.totalSteps) {
      formRef.current.nextStep()
    } else router.push('/')
  }
  const modulesHeaderConfig = {
    title: (
      <>
        <span className="text-gray-500 d-block text-uppercase fs-7">
          Pesquisa
        </span>{' '}
        {survey?.name}
      </>
    ),
    icon: 'clipboard-list-check',
    breadItems: [
      {
        slug: 'estimatedTime',
        name: `Tempo de resposta estimado: ${survey?.estimated_time}`,
        condition: !!survey?.estimated_time
      },
      {
        slug: 'required',
        name: `Resposta: ${survey?.is_required ? 'obrigatória' : 'opcional'}`
      }
    ],
    navItems: [
      {
        slug: 'questions',
        name: 'Questões',
        href: `/pesquisas/${id}/questoes`,
        condition: canManageAll
      },
      {
        slug: 'delivery',
        name: 'Enviar',
        href: `/pesquisas/${id}/enviar`,
        condition: canManageAll
      },
      {
        slug: 'edit',
        name: 'Editar',
        href: `/pesquisas/${id}/editar`,
        condition: canManageAll
      },
      {
        slug: 'answer',
        name: 'Responder',
        href: `/pesquisas/${id}/responder`,
        condition: canManageAll
      }
    ]
  }

  return (
    <Modal show>
      <Modal.Body>
        <LoadWrapper isLoading={isLoadingReplies} isError={isErrorReplies}>
          <StepWizard
            ref={formRef}
            isLazyMount
            isHashEnabled={false}
            transitions={{
              enterRight: 'animate__animated animate__fadeIn',
              enterLeft: 'animate__animated animate__fadeIn',
              exitRight: 'animate__animated animate__fadeOut',
              exitLeft: 'animate__animated animate__fadeOut',
              intro: 'animate__animated animate__fadeIn'
            }}
          >
            {items &&
              _.sortBy(items, 'position').map(item => (
                <SurveyAnswerForm
                  key={item.id}
                  surveyId={survey.id}
                  reply={replies.find(reply => reply.item.id == item.id)}
                  item={item}
                  onSave={handleSave}
                />
              ))}
          </StepWizard>
        </LoadWrapper>
      </Modal.Body>
    </Modal>
  )
}

export default withResourceRecord(SurveyAnswer, getConfig)
