const SurveyAnswerFormFooter = ({
  onHide,
  isSubmitting,
  previousStep,
  currentStep,
  totalSteps
}) => {
  return (
    <>
      {currentStep == 1 ? (
        <></>
      ) : (
        <button
          type="button"
          onClick={previousStep}
          className={'btn btn-light me-4 '}
        >
          Voltar
        </button>
      )}
      {currentStep >= totalSteps && (
        <button
          type="submit"
          disabled={isSubmitting}
          className={'btn btn-success'}
        >
          <i className="fal fa-check"></i>
          {isSubmitting ? 'Finalizando...' : 'Salvar e finalizar'}
        </button>
      )}
      {currentStep < totalSteps && (
        <button
          type="submit"
          disabled={isSubmitting}
          className={'btn btn-primary flex-grow-1 '}
        >
          {isSubmitting ? 'Salvando...' : 'Salvar e avançar'}{' '}
        </button>
      )}
    </>
  )
}

export default SurveyAnswerFormFooter
