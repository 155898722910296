import { useState } from 'react'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import Link from 'next/link'

import { showErrorAlert } from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'
import { MoneyInput, FormControl, ErrorMessage } from '@tootz/react-admin/form'

// import UserAccountFields from '../Fields/Account'
import styles from '../styles.module.scss'

import GoalsGatheringModalStepFooter from './Footer'

import { api } from '@/services/api'

const GoalsGatheringModalFormStep = ({
  id,
  kind,
  step,
  year,
  onSave,
  onHide,
  currentStep,
  previousStep,
  totalSteps
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const methods = useForm({
    defaultValues: { [step.slug]: step.value, motivation: step.motivation }
  })
  const onSubmit = async data => {
    setIsSubmitting(true)

    try {
      let value = data[step.slug]
      if (step.type == 'percentage') value = parseFloat(value)
      if (step.type == 'number') value = parseInt(value)

      await api.put(`/portal/goal_gatherings/${kind}/${id}`, {
        record: { slug: step.slug, value, motivation: data.motivation }
      })

      setIsSubmitting(false)
      onSave()
    } catch (error) {
      setIsSubmitting(false)
      showErrorAlert(error)
    }
  }

  const formatters = {
    money: new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }),
    percentage: new Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: 0
    }),
    number: new Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: 0
    })
  }
  const formatter = _.get(formatters, step.type, formatters.number)
  const min = step.premiss || 0
  const max = Math.max(min, step.max)

  const Field = () => {
    if (step.type == 'money')
      return (
        <MoneyInput
          name={step.slug}
          asCents={false}
          required
          size="lg"
          min={min}
          max={max}
          className={`${styles.Input}`}
        />
      )
    if (step.type == 'percentage')
      return (
        <FormControl
          name={step.slug}
          type="number"
          min={min}
          max={max}
          required
          size="lg"
          className={`${styles.Input}`}
        />
      )
    if (step.type == 'number')
      return (
        <FormControl
          name={step.slug}
          type="number"
          required
          min={min}
          max={max}
          size="lg"
          className={`${styles.Input}`}
        />
      )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="row g-6">
          <div className="col-lg-7">
            <div className="position-relative mb-4 w-100px h-100px pt-4 ps-1 d-flex align-items-center justify-content-center">
              <img
                src="/mej-future/number-bg.gif"
                alt=""
                className="position-absolute top-0 start-0 w-100 h-100"
              />
              <span className="text-white fs-2qx fw-bolder z-index-1">
                0{currentStep - 1}
              </span>
            </div>
            <span
              className={`text-uppercase fs-2qx mb-4 d-block fw-bolder h-70px pe-10 ${styles.Title}`}
            >
              {step.title}
            </span>
            <span className="fs-4 fw-light d-block mb-6 pe-6 h-lg-150px scroll-y">
              {step.description}
            </span>
            {(step.video_url || step.files_url) && (
              <div className="mb-10 mb-md-0">
                <span className="d-block text-gray-100 mb-2">
                  Antes de preencher a meta:
                </span>
                <div className="row">
                  <div className="col-md-6">
                    {step.video_url && (
                      <Link
                        href={step.video_url}
                        passHref
                        className="px-6 py-5 d-flex align-items-center w-100 border border-1 border-white border-hover-primary text-white"
                        target="_blank"
                      >
                        <i className="fal fa-video me-4 text-gray-200 fs-2x"></i>
                        <div className="text-uppercase ">
                          <span className="fs-5 d-block lh-sm">Assista</span>
                          <span className="fs-7 d-block lh-1">ao Vídeo</span>
                        </div>
                      </Link>
                    )}
                  </div>
                  <div className="col-md-6">
                    {step.files_url && (
                      <Link
                        href={step.files_url}
                        passHref
                        className="px-6 py-5 d-flex align-items-center w-100 border border-1 border-white border-hover-primary text-white"
                        target="_blank"
                      >
                        <i className="fal fa-file me-4 text-gray-200 fs-2x"></i>
                        <div className="text-uppercase ">
                          <span className="fs-5 d-block lh-sm">Leia o</span>
                          <span className="fs-7 d-block lh-1">documento</span>
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-5">
            <div className="d-flex flex-column h-100">
              <div className="mt-auto mb-10">
                {step.last_year_reference == 'show' && (
                  <div className="mb-6">
                    <span className="text-gray-300 fs-5 d-block">
                      Realizado em {year - 1}:
                    </span>
                    <span className="fs-3 fw-bolder d-block lh-1">
                      {formatter.format(step.realized)}
                      {step.slug == 'revenue' && !!step.outlier && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="textual-search-tooltip">
                              O valor considerado para cálculo da premissa foi{' '}
                              de{' '}
                              <span className="fw-bold">
                                {formatter.format(step.realized - step.outlier)}
                              </span>{' '}
                              que é o faturamento total de{' '}
                              {formatter.format(step.realized)} menos o valor
                              dos contratos outliers{' '}
                              {formatter.format(step.outlier)}
                            </Tooltip>
                          }
                        >
                          <span className="ms-2">
                            <i
                              className="fal fa-circle-info"
                              style={{
                                color: '#2cccd4'
                              }}
                            ></i>
                          </span>
                        </OverlayTrigger>
                      )}
                    </span>
                  </div>
                )}
                <div className="mb-6">
                  <span className="text-gray-300 fs-5 d-block">Premissa:</span>
                  <span className="fs-1 fw-bolder d-block lh-1">
                    {formatter.format(step.premiss)}
                  </span>
                </div>

                <div className="mb-6">
                  <span className="d-block fs-6">Meta {year}: </span>
                  <Field name={step.slug} required />
                  <ErrorMessage name={step.slug} />
                </div>
                <span className="d-block fs-6">
                  Qual a motivação para esta meta?{' '}
                </span>
                <FormControl
                  name="motivation"
                  as="textarea"
                  required
                  className={`${styles.Input} fs-6`}
                />
                <ErrorMessage name="motivation" />
              </div>
              <div className="d-flex w-100">
                <GoalsGatheringModalStepFooter
                  onHide={onHide}
                  previousStep={previousStep}
                  currentStep={currentStep}
                  totalSteps={totalSteps}
                  isSubmitting={isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

export default GoalsGatheringModalFormStep
