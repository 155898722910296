import { useEffect } from 'react'

import _ from 'lodash'
import { useFormContext, useFieldArray } from 'react-hook-form'

import {
  FormGroup,
  FormControl,
  InputGroup,
  InputMask,
  Select,
  ImageInput,
  DateInput
} from '@tootz/react-admin/form'

import nationalities from './nationalities.json'
import validationSchema from './validationSchema'

const UserAccountFields = () => {
  const { watch, setValue } = useFormContext()
  const watchNationality = watch('nationality')

  useEffect(() => {
    setValue('conditional_validations', ['account'])
  }, [])

  return (
    <>
      <FormControl
        name="conditional_validations"
        defaultValue={['account']}
        hidden
      />
      <FormGroup
        name="avatar_id"
        label="Avatar"
        control={ImageInput}
        mediaApiScope=""
        base64AsValue
        solid
      />
      <FormGroup
        name="name"
        label="Nome completo"
        control={FormControl}
        solid
        required
      />
      <FormGroup
        name="username"
        label="Nome de usuário"
        helpText="Crie um nome de usuário simples e único, assim como nas suas redes sociais"
        solid
        required
      >
        <InputGroup solid>
          <InputGroup.Text>
            <i className="fal fa-at"></i>
          </InputGroup.Text>
          <FormControl name="username" />
        </InputGroup>
      </FormGroup>
      <FormGroup
        name="nationality"
        label="Nacionalidade"
        control={Select}
        options={Object.keys(nationalities).map(slug => ({
          label: _.get(nationalities, slug),
          value: slug
        }))}
        solid
        required
      />
      {watchNationality == 'brazilian' ? (
        <FormGroup
          name="cpf"
          label="CPF"
          control={InputMask}
          mask="999.999.999-99"
          solid
          required
        />
      ) : (
        <FormGroup name="cpf" label="ID" control={FormControl} solid required />
      )}
      <FormGroup
        name="birthdate"
        label="Data de nascimento"
        control={DateInput}
        showYearDropdown
        dropdownMode="select"
        yearDropdownItemNumber={100}
        solid
        defaultValue={new Date(2000, 1, 1)}
        required
      />
      {/* <div className="separator separator-dashed my-6"></div>
      <FormGroup
        name="pronoun"
        label="Pronome"
        control={Select}
        options={pronouns.map(({ title, slug }) => ({
          label: title,
          value: slug
        }))}
        solid
        required
      /> */}
    </>
  )
}

UserAccountFields.validationSchema = validationSchema

export default UserAccountFields
