import _ from 'lodash'

import {
  FormGroup,
  FormControl,
  InputGroup,
  PhoneInput
} from '@tootz/react-admin/form'

import validationSchema from './validationSchema'

const UserProfileFields = () => {
  return (
    <>
      <FormGroup name="full_phone" label="Telefone" solid>
        <InputGroup solid>
          <InputGroup.Text>
            <i className="fal fa-mobile fa-fw"></i>
          </InputGroup.Text>
          <PhoneInput name="full_phone" />
        </InputGroup>
      </FormGroup>
      <FormGroup name="whatsapp" label="WhatsApp" solid>
        <InputGroup solid>
          <InputGroup.Text>
            <i className="fab fa-whatsapp fa-fw"></i>
          </InputGroup.Text>
          <FormControl name="whatsapp" />
        </InputGroup>
      </FormGroup>
      <FormGroup name="linkedin_url" label="LinkedIn" solid>
        <InputGroup solid>
          <InputGroup.Text>
            <i className="fab fa-linkedin-in fa-fw"></i>
          </InputGroup.Text>
          <FormControl name="linkedin_url" type="url" />
        </InputGroup>
      </FormGroup>
      <FormGroup name="instagram_url" label="Instagram" solid>
        <InputGroup solid>
          <InputGroup.Text>
            <i className="fab fa-instagram fa-fw"></i>
          </InputGroup.Text>
          <FormControl name="instagram_url" type="url" />
        </InputGroup>
      </FormGroup>
      <FormGroup name="facebook_url" label="Facebook" solid>
        <InputGroup solid>
          <InputGroup.Text>
            <i className="fab fa-facebook-f fa-fw"></i>
          </InputGroup.Text>
          <FormControl name="facebook_url" type="url" />
        </InputGroup>
      </FormGroup>
    </>
  )
}

UserProfileFields.validationSchema = validationSchema

export default UserProfileFields
