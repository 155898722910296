import _ from 'lodash'
import getSealGatherItems from './partials/sealGathersItems'

import { IES } from '@/services/ability/entities'

const getIESItems = (role, ability) => {
  const basePath = `/ies/${_.get(role, 'resource.id')}`

  let items = [
    {
      slug: 'ies-profile',
      title: 'Perfil',
      icon: 'fal fa-university',
      href: `${basePath}/perfil`
    }
  ]

  items = items.concat(getSealGatherItems(role, basePath))

  items = items.concat([
    {
      slug: 'ies-configurations',
      title: 'Configurações',
      icon: 'fal fa-cog',
      href: `${basePath}/editar`,
      condition: ability.can('edit', new IES(role.resource))
    }
  ])

  return items
}

export default getIESItems
