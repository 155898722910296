import '../styles/main.scss'
import { useEffect } from 'react'

import 'moment/locale/pt-br'
import TimeAgo from 'javascript-time-ago'
import pt from 'javascript-time-ago/locale/pt.json'
import { Settings } from 'luxon'
import moment from 'moment-timezone'
import { Inter } from 'next/font/google'
import { SessionProvider } from 'next-auth/react'
import { CookiesProvider } from 'react-cookie'
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'
import Moment from 'react-moment'
import { ToastContainer } from 'react-toastify'

import { ReleaseVersionRefreshMAnager, ModalProvider } from '@tootz/react-admin'

import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

import { App } from '@/domain/App'
import { AppProvider } from '@/domain/App/context'
import { api } from '@/services/api'

TimeAgo.addDefaultLocale(pt)
TimeAgo.addLocale(pt)

Moment.globalMoment = moment
Moment.globalLocale = 'pt-BR'
Moment.globalFormat = 'DD MMM YYYY'
Moment.globalTimezone = 'America/Sao_Paulo'
Moment.globalLocal = true

Settings.defaultZone = 'America/Sao_Paulo'
Settings.defaultLocale = 'pt-BR'

const isDev = process.env.NODE_ENV == 'development'

const inter = Inter({
  subsets: ['latin'],
  display: 'swap'
})

const defaultQueryFn = async ({ queryKey }) => {
  const { data } = await api.get(queryKey[0])
  return data
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      refetchInterval: 0,
      refetchIntervalInBackground: 60000,
      retry: true,
      refetchOnMount: true,
      retryDelay: attempt =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      queryFn: defaultQueryFn
    }
  }
})

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  useEffect(() => {
    // Google Tag Manager
    if (!isDev) TagManager.initialize({ gtmId: 'GTM-5SF299Z' })

    // Hotjar
    hotjar.initialize({ id: '3826809', debug: isDev })
  }, [])

  useEffect(() => {
    const root = document?.documentElement
    const body = document?.body

    // if (root) root.setAttribute('data-bs-theme', 'light')
    if (body) {
      body.classList.add(inter.className, 'app-default')
      root.setAttribute('id', 'ttz_app_body')
    }

    return () => {
      // if (root) root.removeAttribute('data-bs-theme')
      if (body) {
        body.classList.remove(inter.className, 'app-default')
        root.removeAttribute('id')
      }
    }
  }, [])

  return (
    <main className={`${inter.className} h-100 d-flex flex-column`}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <SessionProvider
            staleTime={5 * 60}
            clientMaxAge={5 * 60}
            refetchInterval={0}
            session={session}
          >
            <CookiesProvider>
              <App>
                <AppProvider>
                  <ModalProvider>
                    <Component {...pageProps} />
                  </ModalProvider>
                </AppProvider>
              </App>
            </CookiesProvider>
          </SessionProvider>
        </Hydrate>
      </QueryClientProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        closeButton={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ReleaseVersionRefreshMAnager />
    </main>
  )
}

export default MyApp
