const CONTRACTS_RELEASED_YEAR = 2018
let CURRENT_DAY_OF_MONTH = new Date().getDate()
let CURRENT_MONTH = new Date().getMonth() + 1
let CURRENT_YEAR = new Date().getFullYear()
let MAX_YEAR = CURRENT_YEAR

if (CURRENT_MONTH == 1 && CURRENT_DAY_OF_MONTH < 10) {
  CURRENT_MONTH = 12
  CURRENT_YEAR--
}

if (CURRENT_MONTH == 12) {
  MAX_YEAR++
}

export const useGraph = () => {
  return {
    CURRENT_MONTH,
    CURRENT_YEAR,
    CONTRACTS_RELEASED_YEAR,
    MAX_YEAR
  }
}

export { CURRENT_MONTH, CURRENT_YEAR, CONTRACTS_RELEASED_YEAR, MAX_YEAR }
