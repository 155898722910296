import { useEffect, useState } from 'react'

import { getCookie, setCookie } from 'cookies-next'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { useRouter } from 'next/router'
import store from 'store2'

import { Dropdown } from '@tootz/react-admin/components'

import SurveyAnswerModal from '@/domain/Surveys/Answer/Modal'
import { api } from '@/services/api'

import SurveyModal from './Modal'

const SURVEYS_COOKIE_KEY = 'bj.surveys-semaphore'
const isDev = process.env.NODE_ENV == 'development'

const SurveyLink = ({ survey, onOpenSurvey }) => {
  return (
    <div
      className="d-flex flex-stack py-4 cursor-pointer"
      onClick={onOpenSurvey}
    >
      <div className="d-flex align-items-start">
        <div className="symbol symbol-35px me-4">
          <span
            className={`symbol-label bg-light-${
              survey.read ? 'success' : 'light'
            }`}
          >
            {survey.read ? (
              <i className="fal fa-check text-success"></i>
            ) : (
              <i className="fal fa-circle text-muted"></i>
            )}
          </span>
        </div>
        <div className="mb-0 me-2">
          <span className="badge badge-light fs-8 mb-2">
            {DateTime.fromISO(survey.created_at).toLocaleString(
              DateTime.DATETIME_MED
            )}
          </span>
          <span className="fs-7 d-block text-gray-800 text-hover-primary fw-bold">
            {survey.survey.name}
          </span>
        </div>
      </div>
    </div>
  )
}

const SurveysTopbarNavItem = () => {
  const [surveys, _setSurveys] = useState([])
  const [openSurvey, setOpenSurvey] = useState(null)
  const [openSurveyAnswer, setOpenSurveyAnswer] = useState(null)
  const router = useRouter()
  const popupSurveys =
    surveys && _.isArray(surveys)
      ? surveys.filter(survey => !survey.answered && survey.survey.is_required)
      : []

  const hasUnreadSurvey = surveys.some(survey => !survey.read)
  const setSurveys = surveys => {
    _setSurveys(surveys || [])
    store('surveys', surveys || [])
  }
  const loadSurveys = () => {
    api
      .get('/admin/survey/surveys/invitations')
      .then(response => {
        setSurveys(response.data)

        setCookie(SURVEYS_COOKIE_KEY, new Date(), {
          expires: new Date(new Date().getTime() + 5 * 60000)
        })
      })
      .catch(error => console.log(error))
  }
  const handleOpenSurvey = survey => {
    // if (isDev) setOpenSurveyAnswer(survey)
    // else

    router.push(`/pesquisas/${survey.survey.id}/responder`)
  }
  const closeSurveyModal = () => setOpenSurvey(null)
  const setSurveyAsRead = surveyId => {
    const newSurveys = surveys.slice()
    const surveyIndex = newSurveys.findIndex(n => n.id == surveyId)
    const survey = newSurveys[surveyIndex]

    survey.read_at = new Date()
    survey.read = true
    survey.answered = true
    survey.answered_at = new Date()
    newSurveys.splice(surveyIndex, 1, survey)

    setSurveys(newSurveys)
  }

  useEffect(() => {
    const surveysSemaphore = getCookie(SURVEYS_COOKIE_KEY)
    const surveysStorage = store('surveys')

    if (
      !isDev &&
      surveysSemaphore &&
      surveysStorage &&
      Array.isArray(surveysStorage)
    ) {
      setSurveys(surveysStorage)
    } else loadSurveys()
  }, [])

  useEffect(() => {
    if (!router.asPath.includes('/pesquisas') && popupSurveys.length > 0)
      setOpenSurvey(popupSurveys[0])
  }, [popupSurveys])

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          flush
          className="btn btn-icon btn-icon-white btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px position-relative"
        >
          <i className="fal fa-clipboard-list-check fs-2"></i>{' '}
          {hasUnreadSurvey && (
            <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink" />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu width={400}>
          <div className="d-flex flex-column bgi-no-repeat rounded-top bg-primary mt-n3">
            <h3 className="text-white fw-bold px-9 mt-10 mb-10 text-center">
              Pesquisas
              <span className="fs-8 opacity-75 ps-3">
                {surveys.length} em aberto
              </span>
            </h3>
          </div>
          <div className="scroll-y mh-325px my-5 px-8">
            {surveys.map(survey => (
              <SurveyLink
                key={survey.id}
                onOpenSurvey={() => handleOpenSurvey(survey)}
                survey={survey}
              />
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>{' '}
      {openSurvey && (
        <SurveyModal
          survey={openSurvey}
          handleClose={surveyId => {
            setSurveyAsRead(surveyId)
            closeSurveyModal()
          }}
        />
      )}
      {openSurveyAnswer && (
        <SurveyAnswerModal
          {...openSurveyAnswer}
          id={openSurveyAnswer.survey.id}
          onHide={() => setOpenSurveyAnswer(false)}
        />
      )}
    </>
  )
}

export default SurveysTopbarNavItem
