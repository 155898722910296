import { Fragment, useEffect, useState } from 'react'

import { Menu, Transition } from '@headlessui/react'
import { getCookie, setCookie } from 'cookies-next'
import Link from 'next/link'
import store from 'store2'

import { api } from '@/services/api'

const TICKETS_COOKIES_KEY = '__bj-ticketsSemaphore'

const SupportTopbarNavItem = () => {
  const [numberOfWaitingTickets, setNumberOfWaitingTickets] = useState(0)

  const hasUnreadTickets = numberOfWaitingTickets > 0

  const ticketsStatusColor = () => {
    if (numberOfWaitingTickets > 9) return 'danger'
    else if (numberOfWaitingTickets > 5) return 'warning'
    else return 'success'
  }

  const setTickets = numberOfWaitingTickets => {
    setNumberOfWaitingTickets(numberOfWaitingTickets)
    store('numberOfWaitingTickets', numberOfWaitingTickets)
  }

  const loadTickets = () => {
    api
      .get('/admin/support/tickets')
      .then(response => {
        setTickets(response.data.number_of_waiting_tickets)

        setCookie(TICKETS_COOKIES_KEY, new Date(), {
          expires: new Date(new Date().getTime() + 5 * 60000)
        })
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    const ticketsSemaphore = getCookie(TICKETS_COOKIES_KEY)
    const numberOfWaitingTicketsStorage = store('numberOfWaitingTickets')

    if (ticketsSemaphore && numberOfWaitingTicketsStorage != null) {
      setTickets(numberOfWaitingTicketsStorage)
    } else loadTickets()
  }, [])

  return (
    <Menu as="div" className="position-relative inline-block text-left">
      <div>
        <Menu.Button className="btn btn-icon btn-icon-white btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px position-relative">
          <i className="fal fa-life-ring fs-2"></i>
          {hasUnreadTickets && (
            <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink" />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="position-absolute end-0   mt-2 w-56 rounded-2 bg-white shadow-lg w-200px w-lg-375px w-md-300px  ">
          <div
            className="d-flex flex-column flex-center bgi-no-repeat bg-primary rounded-top px-9 py-10"
            style={{ backgroundImage: "url('/misc/pattern-1.jpg')" }}
          >
            <h3 className="text-white fw-bold mb-0">Suporte</h3>
          </div>

          <div className="row g-0">
            {/* <div className="col-6"> */}
            <Link
              href="https://forms.gle/A6iD3xLiX2T36e7eA"
              target="_blank"
              passHref
              className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end"
            >
              <i className="fal fa-question-circle fs-2x text-primary mb-2"></i>
              <span className="fs-5 fw-bold text-gray-800 my-2 text-center">
                Fale conosco
              </span>
              <span className="fs-7 text-center d-block text-gray-500">
                Dúvidas, reclamações e elogios
              </span>
            </Link>
            {/* </div> */}
            {/* <div className="col-6">
                <Link href="https://forms.gle/A6iD3xLiX2T36e7eA" passHref>
                  <a className="d-flex flex-column flex-center h-100 p-6 bg-hover-light">
                    <i className="fal fa-comments-alt fs-2x text-primary mb-2"></i>

                    <span className="fs-5 fw-bold text-gray-800 mb-0 text-center my-2">
                      Meus tickets{' '}
                      {hasUnreadTickets && (
                        <span className={`text-${ticketsStatusColor()}`}>
                          {numberOfWaitingTickets}
                        </span>
                      )}
                    </span>
                    <span className="fs-7 text-center d-block text-gray-500">
                      <strong className={`text-${ticketsStatusColor()}`}>
                        {numberOfWaitingTickets}
                      </strong>{' '}
                      respostas em espera
                    </span>
                  </a>
                </Link>
              </div> */}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default SupportTopbarNavItem
