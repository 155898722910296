import _ from 'lodash'
import { DateTime } from 'luxon'
import { FormProvider, useForm } from 'react-hook-form'

import { useSessionContext } from '@tootz/react-admin'

import GoalsGatheringModalStepFooter from './Footer'

const GoalsGatheringModalInitialStep = ({
  id,
  kind,
  year,
  resource,
  configuration,
  onSave,
  onHide,
  currentStep,
  previousStep,
  totalSteps
}) => {
  const { user } = useSessionContext()
  const methods = useForm()
  const onSubmit = data => onSave()

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h1 className="fw-bolder fs-2qx text-white mb-4">
          Coleta de Metas {year}
        </h1>
        <div className="pe-15 fs-4 fw-light mb-6">
          <p>Olá, {user.name.split(' ')[0]}! Que bom ter você aqui!!</p>
          <p>
            Já sabemos que anualmente atualizamos os nossos desafios com o
            intuito de potencializar nossos resultados e conseguir unir forças
            para irmos muito mais além.
          </p>
          <p>
            E para o próximo ano termos um Brasil mais empreendedor, através de
            uma rede com lideranças plurais empreendendo mais soluções
            colaborativas e inovadoras.
          </p>
          {kind == 'ejs' ? (
            <p>
              Por isso, a partir do dia{' '}
              <b>
                {DateTime.fromISO(
                  configuration?.ej_goal_gathering_start_date
                ).toLocaleString(DateTime.DATE_MED)}{' '}
              </b>{' '}
              até{' '}
              <b>
                {DateTime.fromISO(
                  configuration?.ej_goal_gathering_end_date
                ).toLocaleString(DateTime.DATE_MED)}
              </b>{' '}
              todas as EJs no Brasil poderão tangibilizar os seus objetivos
              através da definição de metas para serem alcançadas no ano de{' '}
              {year}.
            </p>
          ) : (
            <p>
              Inicialmente as EJs no Brasil puderam tangibilizar os seus
              objetivos para o próximo ano por meio da definição de metas. Agora
              é a vez das Instâncias (Núcleos e Federações) colocarem os seus
              desafios para {year}. Entre os dias{' '}
              <b>
                {DateTime.fromISO(
                  _.get(
                    configuration,
                    `${kind.slice(0, -1)}_goal_gathering_start_date`
                  )
                ).toLocaleString(DateTime.DATE_MED)}{' '}
              </b>
              e o dia{' '}
              <b>
                {DateTime.fromISO(
                  _.get(
                    configuration,
                    `${kind.slice(0, -1)}_goal_gathering_end_date`
                  )
                ).toLocaleString(DateTime.DATE_MED)}
              </b>
              , estará aberto o período de definição de metas das Instâncias.
            </p>
          )}
          <p>Agora é com você, compartilha aqui com a gente!</p>
        </div>
        <GoalsGatheringModalStepFooter
          onHide={onHide}
          currentStep={currentStep}
          previousStep={previousStep}
          totalSteps={totalSteps}
        />
      </form>
    </FormProvider>
  )
}

export default GoalsGatheringModalInitialStep
