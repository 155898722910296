import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)

const schema = yup.object().shape({
  pronoun: yup.string().nullable().required().label('Pronome'),
  user_profile: yup.object().shape({
    user_personal_info: yup.object().shape({
      gender: yup.string().nullable().required().label('Identidade de gênero'),
      sexual_orientation: yup
        .string()
        .nullable()
        .required()
        .label('Orientação sexual'),
      color: yup.string().nullable().required().label('Cor/Raça'),
      pcd: yup.string().nullable().required().label('PcD'),
      family_income: yup.string().nullable().required().label('Renda familiar')
    })
  })
})

export default schema
