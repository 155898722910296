import _ from 'lodash'

import GoalsGatheringModal from '@/domain/GoalsGathering/Modal'
import { Ej } from '@/services/ability/entities'

import getSealGatherItems from './partials/sealGathersItems'

const isDev = process.env.NODE_ENV === 'development'

const getEjItems = (role, ability, setShowModal) => {
  const basePath = `/ejs/${_.get(role, 'resource.slug')}`

  let items = [
    {
      slug: 'ej-profile',
      title: 'Perfil',
      icon: 'fal fa-rocket-launch',
      href: `${basePath}/perfil`
    },
    // {
    //   slug: 'ej-panels',
    //   title: 'Painéis',
    //   icon: 'fal fa-grid-2',
    //   items: [
    //     {
    //       slug: 'ej-dashboards-network-data',
    //       title: 'Dados da Rede',
    //       href: 'http://bit.ly/DASHDAREDE',
    //       target: '_blank'
    //     }
    //   ]
    // },
    {
      slug: 'ej-solutions',
      title: 'Soluções',
      icon: 'fal fa-lightbulb-on',
      href: `${basePath}/solucoes`
    }
  ]

  if (
    _.get(role, 'resource.status') == 'federated' &&
    _.has(role, 'resource.cnpj')
  ) {
    items.push({
      slug: 'ej-evnttz',
      title: (
        <div className="d-flex align-items-center gap-4">
          Eventos <span className="badge badge-primary">Novo</span>
        </div>
      ),
      icon: 'fal fa-calendar-star',
      condition: ability.can('update', new Ej(role.resource)),
      items: [
        {
          slug: 'evnttz-productor',
          title: 'Área do Produtor',
          href: '/e/produtor'
        },
        {
          slug: 'evnttz-wallet',
          title: 'Minha carteira',
          href: '/e/produtor/minha-carteira'
        },
        {
          slug: 'evnttz-my-tickets',
          title: 'Meus Ingressos',
          href: '/meus-ingressos'
        }
      ]
    })
  }

  if (_.get(role, 'goal_gathering_data.condition', false)) {
    const goalsGatheringSteps = _.get(role, 'goal_gathering_data.steps', 0)
    const goalsGatheringAcceptedTerms = _.get(
      role,
      'goal_gathering_data.accepted_terms',
      true
    )
    const goalsGatheringDoneSteps = _.get(
      role,
      'goal_gathering_data.done_steps',
      0
    )
    const goalsGatheringDoneStepsPercent =
      goalsGatheringDoneSteps / goalsGatheringSteps
    let goalsGatheringDoneStepsVariant = 'danger'
    if (goalsGatheringDoneStepsPercent > 0.5)
      goalsGatheringDoneStepsVariant = 'warning'
    if (goalsGatheringDoneStepsPercent >= 1) {
      goalsGatheringDoneStepsVariant = goalsGatheringAcceptedTerms
        ? 'success'
        : 'danger'
    }

    items.push({
      slug: 'ej-metrics-gather',
      title: (
        <div className="d-flex justify-content-between w-100 align-items-start">
          <span className="me-2">Coleta de Metas</span>
          <div className="d-flex align-items-center">
            <span
              className={`badge badge-${goalsGatheringDoneStepsVariant} px-2 py-2 lh-1`}
            >
              {_.get(role, 'goal_gathering_data.done_steps', 0)}/
              {_.get(role, 'goal_gathering_data.steps', 0)}
            </span>
          </div>
        </div>
      ),
      icon: 'fal fa-bullseye-arrow',
      onClick: () =>
        setShowModal({
          component: GoalsGatheringModal,
          kind: 'ejs',
          id: _.get(role, 'resource.slug')
        })
    })
  }

  if (_.get(role, 'metric_gather_data.condition', false)) {
    _.get(role, 'metric_gather_data.gathers', []).forEach(gather => {
      const completionPercent = gather.done_steps / gather.steps

      return items.push({
        slug: `ej-metrics-gather-${gather.id}`,
        title: (
          <>
            <div className="d-flex justify-content-between w-100 align-items-start">
              <span className="me-2">{gather.title}</span>
              {completionPercent < 1 && (
                <span
                  className="rounded-circle w-10px h-10px bg-light-primary pulse pulse-primary d-flex align-items-center justify-content-center flex-shrink-0"
                  style={{ marginTop: '5px' }}
                >
                  <span className="pulse-ring border-2 w-20px h-20px"></span>
                </span>
              )}
            </div>
          </>
        ),
        icon: 'fal fa-ballot-check',
        href: `${basePath}/coletas-de-metricas/${gather.id}`
      })
    })
  }

  items = items.concat(getSealGatherItems(role, basePath))

  items = items.concat([
    // {
    //   slug: 'ej-documents',
    //   title: 'Documentos',
    //   icon: 'fal fa-folder-open',
    //   href: `${basePath}/documentos`
    // },
    {
      slug: 'ej-tracking',
      title: 'Acompanhamento',
      icon: 'fal fa-file-search',
      href: `${basePath}/acompanhamento`
    },
    {
      slug: 'ej-reports',
      title: 'Relatórios',
      icon: 'fal fa-file-excel',
      href: `${basePath}/relatorios`,
      condition: ability.can('update', new Ej(role.resource))
    },
    {
      slug: 'ej-members',
      title: 'Membros',
      icon: 'fal fa-user-group',
      href: `${basePath}/membros`,
      condition: ability.can('update', new Ej(role.resource))
    },

    {
      slug: 'ej-configurations',
      title: 'Configurações',
      icon: 'fal fa-cog',
      href: `${basePath}/configuracoes`,
      condition: ability.can('update', new Ej(role.resource))
    }
  ])

  return items
}

export default getEjItems
