import _ from 'lodash'

import {
  AddressFields,
  NestedFields,
  ReferenceFields
} from '@tootz/react-admin/form'

import validationSchema from './validationSchema'

const UserProfileFields = () => {
  return (
    <>
      <NestedFields value={['locations']} />
      <ReferenceFields value={['locations']} />
      <AddressFields namePrefix="locations.0" horizontal />
    </>
  )
}

UserProfileFields.validationSchema = validationSchema

export default UserProfileFields
