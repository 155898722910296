import _ from 'lodash'
import Link from 'next/link'

import { FormChecklist } from '@tootz/react-admin/form'

import getTermsConfig from '../../../(admin)/Terms/config'

import validationSchema from './validationSchema'

const UserTermsFields = ({ terms }) => {
  const { getTermKind } = getTermsConfig()

  return (
    <div className="h-400px d-flex flex-column mw-450px mx-auto justify-content-center">
      <FormChecklist
        name="term_ids"
        options={_.reverse(_.sortBy(terms, 'mandatory')).map(term => ({
          label: (
            <>
              {term.term_kind == 'other' ? (
                term.title
              ) : (
                <>
                  Eu aceito os{' '}
                  <Link
                    href={_.get(term, 'file.url')}
                    passHref
                    className="link link-primary fw-bold"
                    target="_blank"
                  >
                    {getTermKind(term.term_kind).name}
                  </Link>{' '}
                  do Portal BJ{' '}
                  {term.mandatory && <span className="text-danger">*</span>}
                </>
              )}
            </>
          ),
          value: term.id
        }))}
        solid
        required
      />
    </div>
  )
}

UserTermsFields.validationSchema = validationSchema

export default UserTermsFields
