import { useState } from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'
import Swal from 'sweetalert2'
import toastr from 'toastr'

import { Modal } from '@tootz/react-admin/components'

import * as Sentry from '@sentry/nextjs'

import { api } from '@/services/api'

export const EvnttzTermsModal = ({ terms, onSave }) => {
  const router = useRouter()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleAccept = async () => {
    setIsSubmitting(true)

    try {
      await api.post('/users/terms/accept', {
        record: { term_ids: terms.map(term => term.id) }
      })

      onSave()
      setIsSubmitting(false)
    } catch (error) {
      Sentry.captureMessage(error)

      toastr.error(
        'Oops',
        'Ocorreu um erro ao tentar aceitar os termos, tente novamente mais tarde'
      )

      setIsSubmitting(false)
    }
  }
  const handleUnnacept = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Tem certeza que deseja sair do Portal BJ?',
      text: 'Ao não aceitar os termos, você será deslogado do Portal BJ e não poderá acessar até aceitá-los',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sair',
      cancelButtonText: 'Voltar e aceitar termos',
      customClass: {
        confirmButton: 'btn btn-light',
        cancelButton: 'btn btn-primary'
      }
    })

    if (isConfirmed) router.push(`${process.env.NEXT_PUBLIC_BJID_URL}/sign-out`)
  }

  return (
    <Modal show centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Icon className="fal fa-ballot-check" />
        <Modal.Title>Termo{terms.length > 1 ? 's' : ''}</Modal.Title>
        <Modal.Subtitle>
          Antes de prosseguir, aceite o{terms.length > 1 ? 's' : ''} termo
          {terms.length > 1 ? 's' : ''} do{terms.length > 1 ? 's' : ''} evento
          {terms.length > 1 ? 's' : ''}
        </Modal.Subtitle>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-auto mw-350px gap-4 d-flex flex-column">
          {terms &&
            terms.map(term => (
              <Link
                key={term.id}
                href={term.file.url}
                passHref
                target="_blank"
                className="p-4 px-6 border rounded-2 border-dashed w-100 d-flex align-items-center text-hover-primary text-dark"
              >
                <span className="fs-5 fw-bold">{term.title}</span>
                <i className="fal fa-arrow-up-right-from-square ms-auto fs-3"></i>
              </Link>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex gap-4">
        <button
          type="button"
          onClick={handleUnnacept}
          className="btn btn-light"
        >
          Não aceito, sair
        </button>
        <button
          className="btn btn-success"
          onClick={handleAccept}
          disabled={isSubmitting}
        >
          Li e aceito os termos
        </button>
      </Modal.Footer>
    </Modal>
  )
}
