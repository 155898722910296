import {
  withResourceRecord,
  useResourceRecordContext
} from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'

import { getConfig } from '../config'
import UserPersonalInfoFields from '../Fields/PersonalInfo'

import UserProfileValidationModalFooter from './StepFooter'

const UserProfileValidationPersonalInfoStep = ({
  onSave,
  onHide,
  currentStep,
  previousStep,
  totalSteps
}) => {
  const { Form, isSubmitting } = useResourceRecordContext()

  return (
    <Form
      validationSchema={UserPersonalInfoFields.validationSchema}
      onSave={onSave}
    >
      <Modal.Header>
        <Modal.Icon className="fal fa-symbols text-primary" />
        <Modal.Title>Dados Pessoais</Modal.Title>
        <Modal.Subtitle>
          Os dados de diversidade não são compartilhados nem apresentados para
          outros usuários, serão utilizados somente para cálculos do indicador
          de Rede Plural de forma anônima
        </Modal.Subtitle>
      </Modal.Header>
      <Modal.Body className="min-h-450px d-flex flex-column justify-content-center">
        <UserPersonalInfoFields />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <UserProfileValidationModalFooter
          onHide={onHide}
          previousStep={previousStep}
          currentStep={currentStep}
          totalSteps={totalSteps}
          isSubmitting={isSubmitting}
        />
      </Modal.Footer>
    </Form>
  )
}

export default withResourceRecord(
  UserProfileValidationPersonalInfoStep,
  ({ id }) => ({
    ...getConfig({ id, my: true }),
    apiBasePath: '/users/personal_info',
    shouldFetch: true,
    edit: true,
    forceIdPresence: false
  })
)
