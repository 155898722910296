import _ from 'lodash'

export const pronouns = [
  { slug: 'Ela/Dela', title: 'Ela/Dela' },
  { slug: 'Ele/Dele', title: 'Ele/Dele' },
  { slug: 'Elu/Delu', title: 'Elu/Delu' }
]
export const genders = [
  { slug: 'woman', title: 'Mulher cis' },
  { slug: 'trans_woman', title: 'Mulher trans' },
  { slug: 'man', title: 'Homem cis' },
  { slug: 'trans_man', title: 'Homem trans' },
  { slug: 'non_binary', title: 'Não-binárie' },
  { slug: 'agender', title: 'Agênero' },
  { slug: 'do_not_declare', title: 'Prefiro não declarar' },
  { slug: 'other', title: 'Outra' }
]
export const sexualOrientations = [
  { slug: 'homo', title: 'Homossexual' },
  { slug: 'hetero', title: 'Heterossexual' },
  { slug: 'bi', title: 'Bissexual' },
  { slug: 'asexual', title: 'Assexual' },
  { slug: 'pan', title: 'Panssexual' },
  { slug: 'other', title: 'Prefiro não declarar' },
  { slug: 'do_not_declare', title: 'Outra' }
]
export const colors = [
  { slug: 'yellow', title: 'Amarelo' },
  { slug: 'white', title: 'Branco' },
  { slug: 'indian', title: 'Indígena' },
  { slug: 'brown', title: 'Pardo' },
  { slug: 'black', title: 'Preto' },
  { slug: 'do_not_declare', title: 'Prefiro não declarar' }
]
export const pcd = [
  { slug: 'not_pcd', title: 'Não sou PcD' },
  { slug: 'physical', title: 'Deficiência física' },
  { slug: 'auditory', title: 'Deficiência auditiva' },
  { slug: 'visual', title: 'Deficiência visual' },
  { slug: 'intellectual', title: 'Deficiência intelectual' },
  { slug: 'multiple', title: 'Deficiência múltipla' },
  { slug: 'other', title: 'Prefiro não declarar' },
  { slug: 'do_not_declare', title: 'Outra' }
]
export const familyIncomes = [
  { slug: '1650', title: 'Menos de 1 salário mínimo e meio (R$ 1.650,00)' },
  {
    slug: '1100_3300',
    title: 'De 1,5 a 3 salários mínimos (R$ 1.100 a R$ 3.300,00)'
  },
  {
    slug: '3300_5500',
    title: 'De 3 a 5 salários mínimos (R$ 3.300,00 a R$ 5.500,00)'
  },
  {
    slug: '5500_16500',
    title: 'De 5 a 15 salários mínimos (R$ 5.500,00 a R$ 16.500,00)'
  },
  { slug: '16500', title: 'Acima de 15 salários mínimos (R$ 16.500,00)' },
  { slug: 'do_not_declare', title: 'Prefiro não declarar' }
]
export const getConfig = (props = {}) => {
  const { id: _id, my } = props

  const config = {
    my,
    humanName: { singular: 'Usuário', plural: 'Usuários' },
    icon: 'user-group',
    getBreadcrumbs: (items = []) => {
      const breadcrumbs = [{ title: 'Usuários', href: '/usuarios' }, ...items]

      return breadcrumbs
    },
    basePath: my ? '/minha-conta' : `/usuarios${_id ? '/' + _id : ''}`,
    forceIdPresence: !my,
    id: my ? null : _id,
    apiBasePath: my ? '/users/my_account' : '/portal/users',
    pronouns,
    getPronoun: slug => _.find(pronouns, { slug }),
    genders,
    getGender: slug => _.find(genders, { slug }),
    sexualOrientations,
    getSexualOrientation: slug => _.find(sexualOrientations, { slug }),
    colors,
    getColor: slug => _.find(colors, { slug }),
    pcd,
    getPcd: slug => _.find(pcd, { slug }),
    familyIncomes,
    getFamilyIncome: slug => _.find(familyIncomes, { slug })
  }

  return config
}

export default getConfig
