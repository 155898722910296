import {
  withResourceRecord,
  useResourceRecordContext
} from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'

import { getConfig } from '../config'
import UserAcademicFields from '../Fields/Academic'

import UserProfileValidationModalFooter from './StepFooter'

const UserProfileValidationAcademicStep = ({
  onSave,
  onHide,
  currentStep,
  previousStep,
  totalSteps
}) => {
  const { Form, isSubmitting } = useResourceRecordContext()

  return (
    <Form
      validationSchema={UserAcademicFields.validationSchema}
      onSave={onSave}
    >
      <Modal.Header>
        <Modal.Icon className="fal fa-graduation-cap text-primary" />
        <Modal.Title>Informações Acadêmicas</Modal.Title>
        <Modal.Subtitle>
          Atualize os dados da sua jornada acadêmica
        </Modal.Subtitle>
      </Modal.Header>
      <Modal.Body className="min-h-450px d-flex flex-column justify-content-center">
        <UserAcademicFields />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <UserProfileValidationModalFooter
          onHide={onHide}
          previousStep={previousStep}
          currentStep={currentStep}
          totalSteps={totalSteps}
          isSubmitting={isSubmitting}
        />
      </Modal.Footer>
    </Form>
  )
}

export default withResourceRecord(UserProfileValidationAcademicStep, getConfig)
