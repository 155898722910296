/* eslint-disable @next/next/no-page-custom-font */
import { useRef } from 'react'

import Head from 'next/head'
import StepWizard from 'react-step-wizard'

import { useRecord } from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'

import GoalsGatheringModalFinalStep from './Steps/Final'
import GoalsGatheringModalFormStep from './Steps/Form'
import GoalsGatheringModalInitialStep from './Steps/Initial'
import styles from './styles.module.scss'

const GoalsGatheringModal = ({ id, kind, onHide, onSave }) => {
  const formRef = useRef()
  const { record = {}, refetch } = useRecord({
    r: `/portal/goal_gatherings/${kind}`,
    id
  })
  const { steps } = record

  const handleSave = () => {
    refetch()

    if (formRef.current.currentStep < formRef.current.totalSteps) {
      formRef.current.nextStep()
    } else location.reload()
  }

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
          rel="stylesheet"
        />
      </Head>
      <Modal
        size="lg"
        centered
        show
        className={styles.Modal}
        contentClassName={styles.ModalContent}
      >
        <Modal.Body className="pt-10 d-flex flex-column position-relative">
          <img
            src="/mej-future/half-moon.png"
            alt=""
            className="position-absolute top-0 end-0 w-100px p-9"
          />
          <img
            src="/mej-future/rectangule-points.png"
            alt=""
            className="position-absolute end-0 w-25px me-10"
            style={{ top: '100px' }}
          />
          <StepWizard
            ref={formRef}
            isLazyMount
            isHashEnabled={false}
            transitions={{
              enterRight: 'animate__animated animate__fadeIn',
              enterLeft: 'animate__animated animate__fadeIn',
              exitRight: 'animate__animated animate__fadeOut',
              exitLeft: 'animate__animated animate__fadeOut',
              intro: 'animate__animated animate__fadeIn'
            }}
          >
            <GoalsGatheringModalInitialStep
              id={id}
              kind={kind}
              {...record}
              onHide={onHide}
              onSave={handleSave}
            />
            {steps &&
              steps.map(step => (
                <GoalsGatheringModalFormStep
                  key={step.slug}
                  id={id}
                  kind={kind}
                  step={step}
                  {...record}
                  onSave={handleSave}
                />
              ))}
            <GoalsGatheringModalFinalStep
              id={id}
              kind={kind}
              {...record}
              onSave={handleSave}
            />
          </StepWizard>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default GoalsGatheringModal
