import _ from 'lodash'

const getUserValidations = user => {
  if (!user) return { accountCompletion: 1, incompleteAccount: false }

  const validations = {
    accountCompletion: 0,
    incompleteAccount: false,
    infos: {
      account: {
        completion: 1,
        keys: [
          'name',
          'username',
          'nationality',
          'cpf',
          'birthdate',
          'locations.0.zip_code'
        ]
      },
      academic: {
        completion: 1,
        keys: user.not_junior
          ? []
          : ['junior.university_id', 'junior.course_id', 'junior.semester']
      },
      personal_info: {
        completion: _.get(user, 'personal_info_completion', 1)
      }
    }
  }
  Object.keys(validations.infos).forEach(infoKey => {
    const infoItems = _.get(validations.infos, `${infoKey}.keys`)
    let infoCompletion = _.get(validations.infos, `${infoKey}.completion`)

    if (infoItems) {
      infoCompletion = 1

      infoItems.forEach(item => {
        if (!_.get(user, item)) infoCompletion -= 1 / infoItems.length
      })

      _.set(validations.infos, `${infoKey}.completion`, infoCompletion)
    }

    validations.accountCompletion +=
      infoCompletion / Object.keys(validations.infos).length
  })

  validations.incompleteAccount = validations.accountCompletion < 1

  return validations
}

export default getUserValidations
