import React, { createContext, useContext, useState } from 'react'

const AppContext = createContext(null)

const useAppContext = () => useContext(AppContext)

const AppProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(null)

  const Modal = ({ component, onHide, onSave, ...rest }) => {
    const Component = component

    return (
      <Component
        {...rest}
        onHide={() => {
          onHide && onHide()
          setShowModal(null)
        }}
        onSave={record => {
          onSave && onSave(record)
          setShowModal(null)
        }}
      />
    )
  }

  return (
    <AppContext.Provider
      value={{
        showModal: setShowModal
      }}
    >
      {children}
      {showModal && <Modal {...showModal} />}
    </AppContext.Provider>
  )
}

// eslint-disable-next-line react/display-name
const withApp = Component => componentProps => {
  return (
    <AppProvider>
      <Component {...componentProps} />
    </AppProvider>
  )
}

export { useAppContext, AppProvider, withApp }
