export class User {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'User'
  }
}

export class IES {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'IES'
  }
}

export class Federation {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'Federation'
  }
}

export class Core {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'Core'
  }
}

export class Ej {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'Ej'
  }
}

export class CustomEj {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'CustomEj'
  }
}

export class Contract {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'Contract'
  }
}

export class ConnectionProduct {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'ConnectionProduct'
  }
}

export class ConnectionProductCategory {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'ConnectionProductCategory'
  }
}

export class CommunitiesPost {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'CommunitiesPost'
  }
}

export class Event {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'Event'
  }
}

export class SealGather {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'SealGather'
  }
}
