import {
  withResourceRecord,
  useResourceRecordContext
} from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'

import { getConfig } from '../config'
import UserAccountFields from '../Fields/Account'

import UserProfileValidationModalFooter from './StepFooter'

const UserProfileValidationAccountStep = ({
  onSave,
  onHide,
  currentStep,
  previousStep,
  totalSteps
}) => {
  const { Form, isSubmitting } = useResourceRecordContext()

  return (
    <Form validationSchema={UserAccountFields.validationSchema} onSave={onSave}>
      <Modal.Header>
        <Modal.Icon className="fal fa-user-pen text-primary" />
        <Modal.Title>Sua Conta</Modal.Title>
        <Modal.Subtitle>Insira as informações da sua conta</Modal.Subtitle>
      </Modal.Header>
      <Modal.Body className="min-h-450px d-flex flex-column justify-content-center">
        <UserAccountFields />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <UserProfileValidationModalFooter
          onHide={onHide}
          previousStep={previousStep}
          currentStep={currentStep}
          totalSteps={totalSteps}
          isSubmitting={isSubmitting}
        />
      </Modal.Footer>
    </Form>
  )
}

export default withResourceRecord(UserProfileValidationAccountStep, getConfig)
