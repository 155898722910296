import { useEffect } from 'react'

import _ from 'lodash'
import { useFormContext } from 'react-hook-form'

import {
  FormGroup,
  Select,
  ReferenceFields,
  FormControl,
  FormCheck
} from '@tootz/react-admin/form'

import validationSchema from './validationSchema'

const UserAcademicFields = () => {
  const { watch, setValue } = useFormContext()
  const watchIES = watch('junior.university_id')
  const watchCourseId = watch('junior.course_id')
  const watchSemester = watch('junior.semester')
  const watchNotJunior = watch('not_junior')

  useEffect(() => {
    if (watchNotJunior) {
      setValue('junior', {
        university_id: null,
        course_id: null,
        semester: null
      })
    }
  }, [watchNotJunior])

  return (
    <>
      <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
        <i className="fal fa-graduation-cap fa-3x text-primary me-4" />
        <div className="d-flex flex-stack flex-grow-1">
          <div className="fw-bold">
            <div className="fs-6 text-gray-700">
              Queremos conhecer sua trajetória acadêmica, nos conte sobre a
              experiência no seu curso mais recente, caso você tenha ou não
              concluído.
            </div>
          </div>
        </div>
      </div>
      <ReferenceFields value={['junior']} />
      <FormControl name="junior.university.id" hidden />
      <FormControl name="junior.university.name_with_acronym" hidden />
      <FormGroup
        name="junior.university_id"
        label="IES - Instituição de Ensino Superior"
        control={Select}
        disabled={!!watchNotJunior}
        async={{
          labelKey: 'name_with_acronym',
          href: '/portal/ies',
          filterKey: 'name_or_acronym_cont'
        }}
        onChange={() => setValue('junior.course_id', null)}
        isClearable
        solid
        required={!watchNotJunior}
      />
      <FormControl name="junior.course.id" hidden />
      <FormControl name="junior.course.full_name" hidden />
      <FormGroup
        name="junior.course_id"
        label="Curso"
        control={Select}
        disabled={!watchIES || !!watchNotJunior}
        async={{
          href: '/courses',
          labelKey: 'full_name',
          search: searchTerm => ({
            name_or_graduation_kind_or_kind_or_university_acronym_or_university_name_cont:
              searchTerm,
            university_id_eq: watchIES
          })
        }}
        isClearable
        solid
        required={!watchNotJunior}
      />
      <FormGroup
        name="junior.semester"
        label="Semestre que iniciou"
        disabled={!watchCourseId || !!watchNotJunior}
        control={Select}
        options={_.reverse(
          _.flatten(
            _.range(1995, new Date().getFullYear() + 1).map(year => [
              year.toString() + '.1',
              year.toString() + '.2'
            ])
          )
        ).map(semester => ({ value: semester, label: semester }))}
        helpText="Semestre que iniciou o curso na universidade"
        solid
        required={!watchNotJunior}
      />
      {!(watchIES && watchCourseId && watchSemester) && (
        <FormGroup
          label=""
          name="not_junior"
          control={FormCheck}
          solid
          text="Nunca cursei em uma Instituição de Ensino"
          helpText="Marque esta opção somente caso nunca tenha sido dicente em uma IES, pois não poderá se vincular à uma Federação, Núcleo ou EJ no Portal."
        />
      )}
    </>
  )
}

UserAcademicFields.validationSchema = validationSchema

export default UserAcademicFields
