import { useState, useEffect } from 'react'

import _ from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import TagManager from 'react-gtm-module'
import store from 'store2'
import ReactPlayer from 'react-player/file'

import { Admin, useDataProvider, cookie } from '@tootz/react-admin'

import * as Sentry from '@sentry/nextjs'

import {
  BRAND_NAME,
  BRAND_SHORT_NAME,
  RELEASE_YEAR,
  SITE_DESCRIPTION,
  SITE_URL,
  TWITTER_NAME
} from '../../../react-admin.config'
import UserProfileValidationModal from '../../(users)/ProfileValidationModal'

import useAbility from '@/services/ability'
import { api } from '@/services/api'
import getUserValidations from '@/services/useUserValidations'

import getAsideMenuItems from './getAsideMenuItems'
import getNavbarMenuItems from './getNavbarMenuItems'
import getTopbarItems from './getTopbarItems'

const brand = {
  name: BRAND_NAME,
  shortName: BRAND_SHORT_NAME,
  siteUrl: SITE_URL
}
const ACTIVE_ROLE_ID_KEY = '__bj-activeRoleId'

export const App = ({ children }) => {
  const router = useRouter()
  const activeRoleId = store(ACTIVE_ROLE_ID_KEY)
  const [currentUser, setCurrentUser] = useState(null)
  const [userValidations, setUserValidations] = useState(null)
  const [showModal, setShowModal] = useState(null)
  const ability = useAbility(currentUser, activeRoleId)
  const canManageAll = ability.can('manage', 'All')
  const dataProvider = useDataProvider('', api)
  const fetcher = (resource, init) =>
    api.get(resource, init).then(res => res.data)
  const asideMenuItems = getAsideMenuItems(currentUser, ability, setShowModal)
  const navbarMenuItems = getNavbarMenuItems(currentUser)
  const topbarItems = getTopbarItems({ currentUser, canManageAll })
  const adminConfig = {
    ability: ability,
    dataProvider: dataProvider,
    fetcher: fetcher,
    httpClient: api,
    session: { user: currentUser, userValidations },
    brand,
    logo: {
      default: '/logo.png',
      light: '/logo-light.png',
      dark: '/logo-dark.png',
      icon: {
        default: '/icon.png',
        light: '/icon-black.png',
        dark: '/icon-light.png'
      }
    },
    containerFluid: false,
    enableColorModes: canManageAll,
    header: {
      containerFluid: true,
      navbarMenuItems: navbarMenuItems,
      center: (
        <Link
          href="/"
          passHref
          className="d-none d-lg-flex align-items-center mx-auto"
        >
          <img
            alt="Brasil Júnior"
            src="/logo-for-enej23/logo-alt-light.png"
            className="h-30px h-md-50px"
          />
        </Link>
      ),
      topbarItems: topbarItems,
      sidebarMobileToggle: {
        iconClassName: 'text-white'
      }
    },
    toolbar: {
      disabled: true
    },
    sidebar: {
      scrollOffset: '80px',
      theme: 'light',
      disableLogo: true,
      // preMenu: (
      //   <Link href="https://www.instagram.com/futurodomej" target="_blank">
      //     <ReactPlayer
      //       url="/misc/clock.mp4"
      //       className="ratio  w-100 h-auto"
      //       muted
      //       playing
      //       loop
      //       width="100%"
      //       height="100%"
      //       style={{ '--bs-aspect-ratio': '56.5%' }}
      //     />
      //   </Link>
      // ),
      menuItems: asideMenuItems,
      footer: userValidations?.incompleteAccount && (
        <div className="pt-5 pb-7 px-5">
          <button
            type="button"
            onClick={() =>
              setShowModal({
                component: UserProfileValidationModal,
                id: currentUser.id
              })
            }
            className="btn btn-dark btn-block w-100"
          >
            <i className="fal fa-edit"></i>
            Complete seu Perfil
          </button>
        </div>
      )
    },
    footer: {
      copyright: (
        <>
          <span className="text-muted fw-bold me-1">
            {RELEASE_YEAR}-{new Date().getFullYear()} ©
          </span>
          <span className="text-gray-800">
            {BRAND_NAME}. Todos os direitos reservados.
          </span>
        </>
      ),
      menuItems: [
        { href: '/termos-e-politicas', title: 'Termos & Políticas' },
        { href: 'https://forms.gle/A6iD3xLiX2T36e7eA', title: 'Suporte BJ' }
      ]
    },
    navbarMenuItems: navbarMenuItems,
    topbarItems: topbarItems
  }

  const Modal = ({ component, onHide, onSave, ...rest }) => {
    const Component = component

    return (
      <Component
        {...rest}
        show
        onHide={() => {
          onHide && onHide()
          setShowModal(null)
        }}
        onSave={record => {
          onSave && onSave(record)
          setShowModal(null)
        }}
      />
    )
  }

  useEffect(() => {
    let currentUser = cookie.getCookie('user')
    let token = cookie.getCookie('token')

    if (currentUser && token) {
      currentUser = JSON.parse(currentUser)
      setCurrentUser(currentUser)

      TagManager.initialize({
        gtmId: 'GTM-MGQX9XT',
        dataLayer: {
          userId: currentUser?.id,
          user: {
            email: currentUser?.email
          }
        }
      })

      api
        .get(
          `${process.env.NEXT_PUBLIC_API_BASE_URL.replace(
            '/v1',
            ''
          )}/oauth/token/info?data_for=portal`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(response => {
          const { user } = response.data

          const orderRoles = role => {
            if (!role.resource_id && !role.resource_type) {
              if (role.name == 'system_admin') return 0
              else if (role.name == 'admin') return 1
              else return 2
            } else {
              const resourceWeights = {
                'Hire::Maintainer': 1,
                Partner: 2,
                University: 3,
                Federation: 4,
                Core: 5,
                Ej: 6
              }
              let weight = _.get(resourceWeights, role.resource_type, 9)

              if (
                [
                  'admin',
                  'sub_dean',
                  'dean',
                  'ej_advisor',
                  'department_boss',
                  'course_coordinator',
                  'teacher',
                  'director'
                ].includes(role.name)
              )
                weight += 10
              else if (
                ['member', 'mentor', 'dean', 'trainee'].includes(role.name)
              )
                weight += 20
              else weight += 30

              return weight
            }
          }
          const roles = _.sortBy(user.roles, orderRoles)

          setUserValidations(getUserValidations({ ...user, roles }))
          setCurrentUser({ ...user, roles, activeRoleId })
        })
        .catch(error => {})
    } else
      router.push(
        `${process.env.NEXT_PUBLIC_BJID_URL}?callbackUrl=${window.location.href}`
      )
  }, [])

  useEffect(() => {
    if (currentUser) Sentry.setUser(currentUser)
  }, [currentUser])

  useEffect(() => {
    const body = document.querySelector('body')

    if (body) {
      body.classList.add('header-primary')
    }

    return () => {
      if (body) {
        body.classList.remove('header-primary')
      }
    }
  })

  if (!currentUser) return <></>

  return (
    <>
      <DefaultSeo
        titleTemplate={`%s - ${BRAND_NAME}`}
        defaultTitle={BRAND_NAME}
        description={SITE_DESCRIPTION}
        lang="pt_BR"
        openGraph={{
          type: 'website',
          url: SITE_URL,
          title: BRAND_NAME,
          description: SITE_DESCRIPTION,
          locale: 'pt_BR',
          site_name: BRAND_NAME
        }}
        facebook={{ appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID }}
        twitter={{
          handle: TWITTER_NAME,
          site: TWITTER_NAME,
          cardType: 'summary_large_image'
        }}
        additionalLinkTags={[
          {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: '/apple-touch-icon.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: '/favicon-32x32.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: '/favicon-16x16.png'
          },
          { rel: 'manifest', href: '/site.webmanifest' }
        ]}
        additionalMetaTags={[
          {
            name: 'msapplication-TileColor',
            content: '#ffffff'
          },
          {
            name: 'theme-color',
            content: '#ffffff'
          },
          { name: 'viewport', content: 'width=device-width, user-scalable=no' }
        ]}
      />
      <Admin {...adminConfig}>
        {children}
        <div className="floating-btns">
          {process.env.NODE_ENV == 'development' && (
            <span className="floating-btn floating-btn-not-expandable bg-danger">
              <i className="fal fa-code text-white floating-btn-logo-icon"></i>
            </span>
          )}
          {cookie.getCookie('previousToken') && (
            <Link
              href={`${process.env.NEXT_PUBLIC_BJID_URL}/sign-in-back`}
              passHref
              className="floating-btn"
            >
              <i className="fal fa-step-backward text-danger floating-btn-logo-icon"></i>
              <span className="floating-btn-text">Retornar ao meu usuário</span>
            </Link>
          )}
        </div>
        {showModal && <Modal {...showModal} />}
      </Admin>
    </>
  )
}
