import _ from 'lodash'

const getConfig = (props = {}) => {
  const {} = props
  const basePath = '/pesquisas'
  const apiBasePath = '/portal/survey/surveys'
  const icon = 'clipboard-list-check'
  const humanName = {
    singular: 'Pesquisa',
    plural: 'Pesquisas'
  }
  const header = {
    toolbarLinks: [
      {
        text: 'Adicionar',
        url: '/pesquisas/novo',
        className: 'btn btn-light-primary btn-sm'
      }
    ]
  }

  const getBreadcrumbs = (items = []) => {
    const breadcrumbs = [
      {
        slug: 'surveys',
        title: humanName.plural,
        href: basePath
      },
      ...items
    ]

    return breadcrumbs
  }

  const filter = {
    surveyable_id_null: true
  }
  const statusOptions = [
    { slug: 'published', title: 'Aberto', variant: 'success' },
    { slug: 'upcoming', title: 'Abre em breve', variant: 'primary' },
    { slug: 'unpublished', title: 'Fechado', variant: 'dark' }
  ]
  const config = {
    basePath,
    apiBasePath,
    icon,
    humanName,
    header,
    getBreadcrumbs,
    filter,
    statusOptions,
    getStatus: slug => _.find(statusOptions, { slug })
  }

  return config
}

export default getConfig
