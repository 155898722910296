import _ from 'lodash'

import GoalsGatheringModal from '@/domain/GoalsGathering/Modal'
import { CURRENT_YEAR } from '@/hooks/useGraph'
import { Federation } from '@/services/ability/entities'

import getSealGatherItems from './partials/sealGathersItems'

const getFederationItems = (role, ability, setShowModal) => {
  const basePath = `/federacoes/${_.get(role, 'resource.id')}`

  let items = [
    {
      slug: 'federation-profile',
      title: 'Perfil',
      icon: 'fal fa-landmark',
      href: `${basePath}/perfil`
    },
    {
      slug: 'federation-panels',
      title: 'Painéis',
      icon: 'fal fa-grid-2',
      items: [
        {
          slug: 'federation-panels-network-data',
          title: 'Dados da Rede',
          href: 'https://bit.ly/dashrede',
          target: '_blank'
        },
        {
          slug: 'federation-panels-mandala',
          title: 'Mandala das Instâncias',
          href: 'https://bit.ly/mandaladasinstancias',
          target: '_blank'
        },
        {
          slug: 'federation-panels-evolution',
          title: 'Evolução das Lideranças',
          href: 'http://bit.ly/mapaevolu%C3%A7aolideran%C3%A7as',
          target: '_blank'
        },
        {
          slug: 'federation-panels-tetris',
          title: 'Tetris',
          href: '/tetris'
        }
      ]
    },
    {
      slug: 'federation-evnttz',
      title: 'Eventos',
      icon: 'fal fa-calendar-star',
      condition: ability.can('manage', new Federation(role.resource)),
      items: [
        {
          slug: 'evnttz-productor',
          title: 'Área do Produtor',
          href: '/e/produtor'
        },
        {
          slug: 'evnttz-wallet',
          title: 'Minha carteira',
          href: '/e/produtor/minha-carteira'
        },
        {
          slug: 'evnttz-my-tickets',
          title: 'Meus Ingressos',
          href: '/meus-ingressos'
        }
        // {
        //   slug: 'federation-events',
        //   title: 'Eventos anteriores',
        //   href: `${basePath}/eventos`
        // }
      ]
    },
    {
      slug: 'connection-products',
      title: 'Produtos de Conexão',
      icon: 'fal fa-users-rays',
      href: '/produtos-de-conexao'
    }
  ]

  if (_.get(role, 'goal_gathering_data.condition', false)) {
    const goalsGatheringSteps = _.get(role, 'goal_gathering_data.steps', 0)
    const goalsGatheringAcceptedTerms = _.get(
      role,
      'goal_gathering_data.accepted_terms',
      true
    )
    const goalsGatheringDoneSteps = _.get(
      role,
      'goal_gathering_data.done_steps',
      0
    )
    const goalsGatheringDoneStepsPercent =
      goalsGatheringDoneSteps / goalsGatheringSteps
    let goalsGatheringDoneStepsVariant = 'danger'
    if (goalsGatheringDoneStepsPercent > 0.5)
      goalsGatheringDoneStepsVariant = 'warning'
    if (goalsGatheringDoneStepsPercent >= 1) {
      goalsGatheringDoneStepsVariant = goalsGatheringAcceptedTerms
        ? 'success'
        : 'danger'
    }

    items.push({
      slug: 'federation-goals-gathering',
      title: (
        <div className="d-flex justify-content-between w-100 align-items-start">
          <span className="me-2">Coleta de Metas</span>
          <div className="d-flex align-items-center">
            <span
              className={`badge badge-${goalsGatheringDoneStepsVariant} px-2 py-2 lh-1`}
            >
              {_.get(role, 'goal_gathering_data.done_steps', 0)}/
              {_.get(role, 'goal_gathering_data.steps', 0)}
            </span>
          </div>
        </div>
      ),
      icon: 'fal fa-bullseye-arrow',
      onClick: () =>
        setShowModal({
          component: GoalsGatheringModal,
          kind: 'federations',
          id: _.get(role, 'resource.id')
        })
    })
  }

  items = items.concat(getSealGatherItems(role, basePath))

  items = items.concat([
    {
      slug: 'federation-operation-revenue',
      title: 'Receita Operacional',
      icon: 'fal fa-briefcase',
      href: `${basePath}/receita-operacional/${CURRENT_YEAR}`
    },
    {
      slug: 'federation-monitoring',
      title: 'Monitoramento',
      icon: 'fal fa-chart-area',
      items: [
        {
          slug: 'federation-monitoring-cores',
          title: 'Núcleos',
          href: `${basePath}/nucleos/monitoramento/${CURRENT_YEAR}`
        },
        {
          slug: 'federation-monitoring-ejs',
          title: 'EJs',
          href: `${basePath}/ejs/monitoramento/${CURRENT_YEAR}`
        }
      ]
    },
    {
      slug: 'federation-seals',
      title: 'Selo',
      icon: 'fal fa-badge-check',
      items: [
        {
          slug: 'federation-solutions-cores',
          title: 'Núcleo',
          href: `${basePath}/nucleos/selo`
        },
        {
          slug: 'federation-solutions-ejs',
          title: 'EJ',
          href: `${basePath}/ejs/selo`
        }
      ]
    },
    { slug: 'federation-divider-1', isSeparator: true },
    // {
    //   slug: 'federation-ies',
    //   title: 'IES',
    //   icon: 'fal fa-university',
    //   href: `${basePath}/ies`
    // },
    {
      slug: 'federation-cores',
      title: 'Núcleos',
      icon: 'fal fa-archway',
      href: `${basePath}/nucleos`
    },
    {
      slug: 'federation-ejs',
      title: 'EJs',
      icon: 'fal fa-rocket-launch',
      href: `${basePath}/ejs`
    },
    { slug: 'federation-divider-2', isSeparator: true },
    // {
    //   slug: 'federation-documents',
    //   title: 'Documentos',
    //   icon: 'fal fa-folder-open',
    //   href: `${basePath}/meus-documentos/${CURRENT_YEAR}`
    // },
    {
      slug: 'federation-reports',
      title: 'Relatórios',
      icon: 'fal fa-file-excel',
      href: `${basePath}/relatorios`,
      condition: ability.can('edit', new Federation(role.resource))
    },
    {
      slug: 'federation-users',
      title: 'Membros',
      icon: 'fal fa-user-group',
      href: `${basePath}/membros`,
      condition: ability.can('edit', new Federation(role.resource))
    },

    {
      slug: 'federation-configurations',
      title: 'Configurações',
      icon: 'fal fa-cog',
      href: `${basePath}/configuracoes`,
      condition: ability.can('edit', new Federation(role.resource))
    }
  ])

  return items
}

export default getFederationItems
