import {
  useRecords,
  useResourceRecordContext,
  withResourceRecord
} from '@tootz/react-admin'
import { ErrorAlert, LoadingAlert, Modal } from '@tootz/react-admin/components'

import { getConfig } from '../config'
import UserTermsFields from '../Fields/Terms'

import { api } from '@/services/api'

import UserProfileValidationModalFooter from './StepFooter'

const UserProfileValidationTermsStep = ({
  onSave,
  onHide,
  currentStep,
  previousStep,
  totalSteps
}) => {
  const { Form, isSubmitting } = useResourceRecordContext()
  const {
    records: terms,
    isLoading,
    isError,
    isSuccess
  } = useRecords('/users/terms', {
    filter: { termable_type_null: true },
    pagination: {
      per_page: 99
    }
  })
  const selectedTermIds = terms
    .filter(term => term?.acceptance?.accepted_at)
    .map(term => term.id)
  const handleBeforeSubmit = record => {
    const { term_ids } = record

    if (!term_ids || !term_ids.length)
      throw new Error('Antes de prosseguir, aceite os termos.')

    const mandatoryTerms = terms
      .filter(term => term.mandatory)
      .map(term => term.id)
    const mandatoryNotSelected = mandatoryTerms.some(
      termId => !term_ids.includes(termId)
    )

    if (mandatoryNotSelected)
      throw new Error(
        'Antes de prosseguir, aceite os termos obrigatórios identificados com um asterisco vermelho.'
      )

    return record
  }

  const handleSubmit = record =>
    api.post('/users/terms/accept', {
      record
    })

  if (isLoading) return <LoadingAlert />
  if (isError) return <ErrorAlert />
  return (
    <Form
      validationSchema={UserTermsFields.validationSchema}
      beforeSave={handleBeforeSubmit}
      onSave={onSave}
      onSubmit={handleSubmit}
      defaultValues={{ term_ids: selectedTermIds }}
    >
      <Modal.Header>
        <Modal.Icon className="fal fa-list-check text-primary" />
        <Modal.Title>Termos</Modal.Title>
        <Modal.Subtitle>
          Leia atentamente e aceite os termos para utilização segura do Portal
          Brasil Júnior
        </Modal.Subtitle>
      </Modal.Header>
      <Modal.Body className="min-h-450px d-flex flex-column justify-content-center">
        <UserTermsFields terms={terms} />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <UserProfileValidationModalFooter
          onHide={onHide}
          previousStep={previousStep}
          currentStep={currentStep}
          totalSteps={totalSteps}
          isSubmitting={isSubmitting}
        />
      </Modal.Footer>
    </Form>
  )
}

export default withResourceRecord(UserProfileValidationTermsStep, getConfig)
