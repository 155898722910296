import { useState } from 'react'

import { DateTime } from 'luxon'
import ReactTimeAgo from 'react-time-ago'
import toastr from 'toastr'

import { showErrorAlert } from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'

import { api } from '@/services/api'

export const NotificationsModal = ({
  id,
  read,
  read_at,
  created_at,
  message,
  onSave,
  onHide
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [readAt, setReadAt] = useState(read_at)
  const createdAt = DateTime.fromISO(message.created_at)
  const old = createdAt.diffNow().as('days') < -7

  const handleMarkAsread = async () => {
    setIsSubmitting(true)

    try {
      await api.post(`/portal/notification/messages/${id}/toggle_read`)

      toastr.success('Notificação marcada como lida', 'Feito!')
      setReadAt(new Date().toISOString())
    } catch (error) {
      showErrorAlert(error)
    }

    setIsSubmitting(false)
  }

  const isBlocking = !readAt && message.kind == 'blocking'

  return (
    <Modal
      centered
      show
      backdrop={isBlocking ? 'static' : true}
      keyboard={!isBlocking}
      onHide={() => !isBlocking && onHide(id)}
      className={message.publish_kind}
      backdropClassName={message.publish_kind}
    >
      <Modal.Header
        className="position-absolute end-0 z-index-1"
        onHide={onHide}
      ></Modal.Header>
      <Modal.Body className="pt-15 pb-15 min-h-300px d-flex flex-column">
        {/* {message.publish_kind == 'mej_future' && (
          <img
            src="/mej-future/logo.png"
            alt=""
            height="40"
            className="modal-icon"
          />
        )} */}
        <span className="fw-bolder fs-2 text-dark d-block mb-2">
          {message.title}
        </span>
        <div className="d-flex justify-content-between align-items-center fs-7 text-gray-600 mb-8">
          {old ? (
            <span className="text-capitalize">
              {createdAt.toFormat('cccc HH:mm')}
            </span>
          ) : (
            <ReactTimeAgo
              date={createdAt.toJSDate()}
              className="text-capitalize"
            />
          )}
          <span className="text-capitalize">
            {createdAt.toFormat('LLL dd, yyyy')}
          </span>
        </div>
        <div
          className="fs-5 fw-semibold d-block mb-10 lh-2"
          dangerouslySetInnerHTML={{ __html: message.content }}
        />
        <div className="d-flex justify-content-between align-items-center mt-auto">
          {message?.link_title && message?.link_url && (
            <a
              href={message.link_url}
              target="_blank"
              className="btn btn-info modal-primary-btn btn-sm"
              rel="noreferrer"
            >
              {(message.link_title || 'Acessar').replace('https://', '')}
            </a>
          )}

          <div className="ms-auto">
            {readAt ? (
              <span className="text-success mt-1">
                <i className="far fa-check-circle me-1 text-success"></i>
                Lido em {DateTime.fromISO(readAt).toFormat('DD')}
              </span>
            ) : (
              <button
                type="button"
                className="btn btn-flush btn-sm fw-bold text-hover-info"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()

                  handleMarkAsread()
                }}
                disabled={isSubmitting}
              >
                <i className="fal fa-check-double text-dark"></i>
                Marcar como lido
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
