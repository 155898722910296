import { useState } from 'react'

import { useForm, FormProvider, useFormContext } from 'react-hook-form'

import { Modal } from '@tootz/react-admin/components'
import { FormCheck, ErrorMessage } from '@tootz/react-admin/form'
import { useSessionContext, showErrorAlert } from '@tootz/react-admin'

import styles from '../styles.module.scss'

import GoalsGatheringModalStepFooter from './Footer'

import { api } from '@/services/api'

const GoalsGatheringModalInitialStep = ({
  id,
  year,
  kind,
  onSave,
  onHide,
  currentStep,
  previousStep,
  totalSteps
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { user } = useSessionContext()
  const methods = useForm()
  const onSubmit = async data => {
    setIsSubmitting(true)
    try {
      await api.post(`/portal/goal_gatherings/${kind}/${id}/accept_terms`)

      setIsSubmitting(false)
      onSave()
    } catch (error) {
      setIsSubmitting(false)
      showErrorAlert(error)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h1 className="fw-bolder fs-2x text-white mb-4">
          <p>{user.name.split(' ')[0]}, que bom te encontrar desse lado!</p>
        </h1>
        <div className="pe-15 fs-4 fw-light mb-6">
          <p>
            Sem dúvidas, esse será o primeiro passo para enfrentarmos uma nova e
            intensa jornada juntes. Muito obrigado por se desafiar mais uma vez
            conosco!
          </p>
          <p>
            Juntes, lutaremos todos os dias por um <b>Brasil Empreendedor</b>:
            <br />
            - Mais competitivo, com empresas melhores, governos melhores e
            universidades melhores;
            <br />
            - Mais ético, íntegro e comprometido com a verdade;
            <br />
            - Mais educador, que empodera as pessoas a realizarem as mudanças
            necessárias;
            <br />
            - Mais colaborativo, em que os atores integrados geram
            microrrevolucões em todo o Brasil;
            <br />- E mais diverso, que incentiva a formação de lideranças
            plurais.
          </p>
          <p>
            Acreditamos que esse Brasil será construído por meio de uma geração
            inconformada com a sua realidade, com a coragem de sonhar e a
            ousadia de agir. Ao assumirmos esses novos desafios, estamos, todos
            os dias, formando, por meio da vivência empresarial, cada vez mais
            lideranças conscientes, comprometidas e capazes de transformar o
            país.
          </p>
          <p>Vamos juntes transformar o Brasil em {year}!</p>
          <span className="separator my-4"></span>
          <p className="fs-7">
            Ao clicar neste botão, você declara estar ciente de que as metas
            inseridas serão consideradas as metas oficiais da{' '}
            {kind == 'ejs' ? 'EJ' : 'Instância'} para o Planejamento Estratégico
            da Rede para 22-24, sendo todas verdadeiras e de comum acordo com os
            representantes atuais da {kind == 'ejs' ? 'EJ' : 'Instância'}.
            Também estando ciente de que tais metas não poderão ser alteradas.
            Além disso, me comprometo a fornecer todos os dados que serão
            solicitados para mensuração e auditoria dos resultados, assim como
            também seguir os direcionamentos contidos da estrutura de apoio das
            premissas e fundamentos do Planejamento Estratégico 2022-2024.
          </p>
        </div>

        <GoalsGatheringModalStepFooter
          currentStep={currentStep}
          previousStep={previousStep}
          totalSteps={totalSteps}
          isSubmitting={isSubmitting}
        />
      </form>
    </FormProvider>
  )
}

export default GoalsGatheringModalInitialStep
