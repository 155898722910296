import { isMobile } from 'react-device-detect'

export const navbarMenuItems = [
  {
    slug: 'aside-toggle',
    content: (
      <div
        id="ttz_aside_toggle"
        className="me-3 btn-sm btn btn-icon btn-icon-white btn-active-color-white aside-toggle d-none d-lg-flex"
        data-ttz-toggle="true"
        data-ttz-toggle-state="active"
        data-ttz-toggle-target="body"
        data-ttz-toggle-name="app-sidebar-minimize"
        title="Esconder menu"
      >
        <span className="svg-icon svg-icon-1 rotate-180">
          <i className="fal fa-chevron-double-left"></i>
        </span>
      </div>
    )
  },
  {
    slug: 'home',
    title: 'Início',
    icon: 'fal fa-home',
    href: '/'
  },
  {
    slug: 'dashboard',
    title: (
      <>
        Dashboard
        <i className="fal fa-arrow-up-right-from-square ms-2 text-white"></i>
      </>
    ),
    href: 'http://bit.ly/DashRede',
    icon: 'fal fa-dashboard',
    target: '_blank'
  },
  {
    slug: 'academy',
    title: <>Academy</>,
    icon: 'fal fa-chalkboard-teacher',
    items: [
      {
        slug: '/academy',
        title: 'Calendário de Mentorias',
        href: '/academy'
      },
      {
        slug: '/academy/mentores',
        title: 'Mentores',
        items: [
          {
            slug: '/academy/mentores',
            title: 'Lista',
            href: '/academy/mentores'
          },
          {
            slug: '/academy/mentorias',
            title: 'Mentorias',
            href: '/academy/mentorias'
          }
        ]
      },

      {
        slug: '/academy/cursos',
        title: 'Cursos',
        href: '/academy/cursos',
        items: [
          {
            slug: '/academy/cursos-todos',
            title: 'Lista',
            href: '/academy/cursos'
          },
          {
            slug: '/academy/cursos-assinaturas',
            title: 'Assinaturas',
            href: '/academy/cursos-assinaturas'
          }
        ]
      },

      {
        slug: '/academy/experiencias',
        title: 'Experiências',
        href: '/academy/experiencias'
      },
      // {
      //   slug: '/academy/intercambio',
      //   title: 'Intercâmbio',
      //   href: '/academy/intercambio'
      // },
      {
        slug: '/academy/portal-carreiras',
        title: 'Vagas',
        href: '/academy/portal-carreiras'
      },
      {
        slug: '/academy/meu-cv',
        title: 'Meu Currículo',
        href: '/academy/meu-cv'
      },
      {
        slug: '/academy/suporte',
        title: 'Suporte',
        href: '/academy/suporte'
      }
    ]
  },
  {
    slug: 'mej-future',
    title: (
      <>
        @futurodomej
        <span className="badge badge-light-primary ms-2 px-2 py-2">Novo</span>
      </>
    ),
    icon: 'fal fa-star-shooting',
    href: 'https://www.instagram.com/futurodomej/?hl=pt-br',
    target: '_blank'
  },
  {
    slug: 'tools',
    title: 'Ferramentas',
    icon: 'fal fa-screwdriver-wrench',
    items: [
      {
        slug: 'tools-cluster-calculator',
        title: 'Calculadora de Cluster',
        href: '/ferramentas/calculadora-de-cluster'
      },
      {
        slug: 'tools-innovative-solutions-calculator',
        title: 'Soluções Inovadoras',
        href: '/ferramentas/calculadora-de-solucoes-inovadoras'
      }
    ]
  }
]

export const getNavbarMenuItems = () => {
  if (isMobile) return []

  return navbarMenuItems
}

export default getNavbarMenuItems
