import Link from 'next/link'

import getQuickUserMenuItems from './getQuickUserMenuItems'
import AccessibilityTopbarNavItem from './Topbar/AccessibilityNavItem'
import { NotificationsNavItem } from './Topbar/Notifications'
import SupportTopbarNavItem from './Topbar/SupportNavItem'
import SurveysTopbarNavItem from './Topbar/SurveysNavItem'
import { TopbarTemsNavItem } from './Topbar/TermsNavItem'

const getTopbarItems = ({ currentUser, canManageAll }) => {
  const topbarItems = [
    {
      slug: 'terms',
      component: <TopbarTemsNavItem />
    },
    {
      slug: 'site',
      component: (
        <div className="d-flex align-items-center ms-1 ms-lg-3">
          <Link
            href={process.env.NEXT_PUBLIC_SITE_BASE_URL}
            passHref
            target="_blank"
            className="btn btn-icon btn-icon-white btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px position-relative"
          >
            <i className="fal fa-globe fs-2"></i>
          </Link>
        </div>
      )
    },
    {
      slug: 'notifications',
      component: <NotificationsNavItem />
    },

    {
      slug: 'surveys',
      component: (
        <div className="d-flex align-items-center ms-1 ms-lg-3">
          <SurveysTopbarNavItem />
        </div>
      )
    },
    {
      slug: 'support',
      component: (
        <div className="d-flex align-items-center ms-1 ms-lg-3">
          <SupportTopbarNavItem />
        </div>
      )
    },
    {
      slug: 'accessibility',
      component: (
        <div className="d-flex align-items-center ms-1 ms-lg-3">
          <AccessibilityTopbarNavItem />
        </div>
      )
    },
    {
      slug: 'colorModes',
      condition: canManageAll
    },
    {
      slug: 'quick-user',
      config: {
        hideName: true,
        menuItems: getQuickUserMenuItems(currentUser)
      }
    }
  ]

  return topbarItems
}

export default getTopbarItems
